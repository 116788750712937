import { Component } from '@angular/core';

import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';

import { delay, map } from 'rxjs/operators';
import { WarlordUserService } from './settings/user/user.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ArmyBuilderConfig, HttpClientWithInFlightCache, NativeStorageService, SupportService, ThemeService } from 'army-builder-shared';
import { MenuItem } from './global/menu/menu.component';
import { environment } from '../environments/environment';
import pkg from '../../package.json';
import { SubscriptionService } from './subscription/subscription.service';

@Component({
    selector: 'app-root',
    template: `
        <ion-app
            *ngLet="{
                otherClientActive: otherClientActive$ | async,
                hasAccess: subscriptionService.userHasAccess$ | async,
                appPages: appPages$ | async
            } as props"
        >
            <ion-split-pane contentId="mainRouterOutlet">
                <ion-menu type="overlay" side="end" contentId="mainRouterOutlet" *ngIf="props.appPages">
                    <ion-header>
                        <ion-toolbar>
                            <ion-title>{{ 'GLOBAL.MENU' | translate }}</ion-title>
                        </ion-toolbar>
                    </ion-header>
                    <ion-content>
                        <wl-menu [menuItems]="props.appPages" [isLoggedIn]="props.hasAccess" [mode]="'root'"></wl-menu>
                    </ion-content>
                    <ion-footer>
                        <a href="#" (click)="reportProblem($event)" class="support">{{ 'GLOBAL.SUPPORT.LINK' | translate }}</a>
                        |
                        <a href="mailto:warlord@maloric.com" class="contact">{{ 'GLOBAL.MENU_ITEMS.CONTACT' | translate }}</a>
                        |
                        <span class="version">{{ version }}</span>
                    </ion-footer>
                </ion-menu>
                <ion-router-outlet [class]="theme$ | async" id="mainRouterOutlet"></ion-router-outlet>
            </ion-split-pane>
            <div class="unsupportedClient" *ngIf="supported === false">
                <ion-card>
                    <h1>{{ 'GLOBAL.UNSUPPORTED_CLIENT.TITLE' | translate }}</h1>
                    <p>
                        {{
                            'GLOBAL.UNSUPPORTED_CLIENT.MESSAGE'
                                | translate
                                    : {
                                          minClientVersion: this.minClientVersion,
                                          currentClientVersion: this.currentClientVersion
                                      }
                        }}
                    </p>
                </ion-card>
            </div>

            <div class="useHere" *ngIf="props.otherClientActive">
                <ion-card>
                    <h1>{{ 'GLOBAL.UNSUPPORTED_CLIENT.TITLE' | translate }}</h1>
                    <p>
                        {{
                            'GLOBAL.UNSUPPORTED_CLIENT.MESSAGE'
                                | translate
                                    : {
                                          minClientVersion: this.minClientVersion,
                                          currentClientVersion: this.currentClientVersion
                                      }
                        }}
                    </p>
                </ion-card>
            </div>
        </ion-app>
    `,
    styles: [
        `
            .logo {
                max-width: 200px;
            }

            ion-footer {
                padding: 5px;
                background: #5b5b5b;
                color: white;
            }

            ion-footer a {
                color: white;
            }

            .version,
            .contact,
            .support {
                padding: 0 10px;
            }

            .unsupportedClient {
                position: fixed;
                display: flex;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background: rgba(0, 0, 0, 1);
                align-items: center;
                justify-content: center;
            }

            .unsupportedClient h1 {
                font-family: Nidex;
                font-size: 1.4em;
                text-transform: uppercase;
            }

            .unsupportedClient ion-card {
                padding: 20px;
                text-align: center;
                max-width: 600px;
                font-family: Univers;
                font-size: 1.2em;
                line-height: 1.6em;
            }
        `
    ]
})
export class AppComponent {
    affId = environment.affiliateId;
    version: string = environment.appVersion;
    supported = null;
    minClientVersion = null;
    currentClientVersion = null;
    otherClientActive$ = new BehaviorSubject<boolean>(false);

    public appPages$: Observable<MenuItem[]> = this.userService.login$.pipe(
        // select(LoginSelector),
        map((login) => {
            const isAdmin = login.user?.roles?.includes('app_admin');
            let menuItems: MenuItem[] = [
                {
                    title: 'GLOBAL.MENU_ITEMS.BOLT_ACTION',
                    children: [
                        { title: 'GLOBAL.MENU_ITEMS.LIST_BUILDER', icon: 'list', url: '/bolt-action/forces' },
                        { title: 'GLOBAL.MENU_ITEMS.LIBRARY', icon: 'list', url: '/bolt-action/library' }
                    ],
                    requiresLogin: true
                },
                {
                    title: 'GLOBAL.MENU_ITEMS.BLACK_SEAS',
                    children: [
                        { title: 'GLOBAL.MENU_ITEMS.LIST_BUILDER', icon: 'list', url: '/black-seas/forces' },
                        { title: 'GLOBAL.MENU_ITEMS.BATTLE_TRACKER', icon: 'list', url: '/black-seas/battle' }
                    ],
                    requiresLogin: true
                },
                {
                    title: 'GLOBAL.MENU_ITEMS.BLOOD_RED_SKIES',
                    children: [
                        { title: 'GLOBAL.MENU_ITEMS.LIST_BUILDER', icon: 'list', url: '/blood-red-skies/forces' },
                        { title: 'GLOBAL.MENU_ITEMS.LIBRARY', icon: 'list', url: '/blood-red-skies/library' }
                    ],
                    requiresLogin: true
                },

                {
                    title: 'GLOBAL.MENU_ITEMS.OTHER',
                    children: [
                        {
                            title: 'GLOBAL.MENU_ITEMS.SETTINGS',
                            icon: 'cog',
                            url: '/settings',
                            requiresLogin: true
                        },
                        {
                            title: 'GLOBAL.MENU_ITEMS.SUPPORT_TICKETS',
                            icon: 'flag-outline',
                            url: '/support',
                            requiresLogin: true
                        },
                        {
                            title: 'GLOBAL.MENU_ITEMS.PATCH_NOTES',
                            icon: 'list',
                            url: '/pages/patch-notes'
                        },
                        {
                            title: 'GLOBAL.MENU_ITEMS.KNOWN_ISSUES',
                            icon: 'bug-outline',
                            url: '/pages/known-issues'
                        },
                        {
                            title: 'GLOBAL.MENU_ITEMS.FAQ',
                            icon: 'help-circle-outline',
                            url: '/pages/faq'
                        },
                        {
                            title: 'GLOBAL.LOGIN.BUTTONS.LOGOUT',
                            icon: 'log-out-outline',
                            onClick: () => this.userService.logout()
                        }
                    ]
                }
            ];

            if (isAdmin) {
                menuItems.push({
                    title: 'GLOBAL.MENU_ITEMS.ADMIN',
                    icon: 'create',
                    url: '/admin'
                });

                menuItems[0].children.push({
                    title: 'GLOBAL.MENU_ITEMS.COLLECTION_MANAGER',
                    icon: 'list',
                    url: '/bolt-action/collection-manager'
                });
            }

            return menuItems;
        })
    );

    public theme$ = this.themeService.theme$.pipe(delay(0));

    constructor(
        private platform: Platform,
        private userService: WarlordUserService,
        private themeService: ThemeService,
        private menu: MenuController,
        private http: HttpClientWithInFlightCache,
        private config: ArmyBuilderConfig,
        protected subscriptionService: SubscriptionService,
        private supportService: SupportService,
        private storage: NativeStorageService
    ) {
        this.initializeApp();
        this.userService.login$.subscribe((l) => {
            this.menu.enable(!!l.user);
        });
    }

    async initializeApp() {
        let previousVersion = await this.storage.getItem('appVersion', null);
        let currentVersion = pkg.version;

        console.log(`Caching app version ${currentVersion}`);
        await this.storage.setItem('appVersion', currentVersion);

        let timeToClearData = !this.versionCheck(previousVersion, (pkg as any).clearDataBeforeVersion);
        if (!previousVersion || timeToClearData) {
            console.log('Clearing app data');
            this.storage.clear();
            window.location.reload();
            return;
        }

        this.platform.ready().then((platform) => {
            if (platform !== 'dom') {
                StatusBar.setStyle({ style: Style.Default });
            }
            SplashScreen.hide();

            this.http.get(`${this.config.apiBaseUrl}/healthcheck`).subscribe((res: any) => {
                this.minClientVersion = res.minClientVersion;
                this.currentClientVersion = currentVersion;
                this.supported = this.versionCheck(currentVersion, res.minClientVersion);
                console.log(`Client version ${currentVersion}, API version: ${res.minClientVersion}, supported: ${this.supported}`);
            });
        });
    }

    /**
     * Returns true if the client is greater than
     * or equal to the minClient
     */
    versionCheck(client, minClient) {
        let clientVersion = this.getVersionParts(client);
        let minClientVersion = this.getVersionParts(minClient);

        return this.isClientSupported(clientVersion, minClientVersion);
    }

    getVersionParts(version: string) {
        return version.split('.').map((x) => parseInt(x));
    }

    isClientSupported(client, api) {
        for (let i = 0; i < client.length; i++) {
            if (client[i] < api[i]) {
                return false;
            }
            if (client[i] > api[i]) {
                return true;
            }
        }
        return true;
    }

    reportProblem(e?) {
        if (e) {
            e.preventDefault();
        }
        this.supportService.showModal();
    }
}

import QRCode from 'qrcode';
import { ElementRef, Injectable } from '@angular/core';
import { from, Observable, of, Subscription } from 'rxjs';
import { catchError, map, take, switchMap } from 'rxjs/operators';
import { DebugService, Logger } from '../debug';
import { ForceService } from '../../forces/force.service';
import { AlertController } from '@ionic/angular';
import { Force } from '../../forces/models';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { CompressedForce } from './models';
import ShortId from 'id-shorter';
import { UnitTemplateLibrary } from '../unit-library';

@Injectable()
export class QRCodeService {
    private debug: Logger;
    constructor(
        private barcodeScanner: BarcodeScanner,
        private forceService: ForceService,
        private alertController: AlertController,
        errorLogger: DebugService,
        private unitTemplateLibrary: UnitTemplateLibrary
    ) {
        this.debug = errorLogger.getInstance('[QRCodeService]');
    }

    generate(canvas: ElementRef, force: Force) {
        this.debug.log('Generating shareable force.');
        const shareableForce = this.getShareableForce(force);
        this.debug.log('Generated shareable force: ', JSON.stringify(shareableForce, null, 4));
        let data = JSON.stringify(shareableForce);
        console.log({ data: JSON.stringify(shareableForce).length });
        QRCode.toCanvas(canvas.nativeElement, data.toString(), function (error) {
            if (error) {
                console.error(error);
            }
        });
    }

    generateDataUrl(force: Force) {
        return new Promise<string>((resolve, reject) => {
            const shareableForce = this.getShareableForce(force);
            QRCode.toDataURL(JSON.stringify(shareableForce), function (error, url) {
                if (error) {
                    reject(error);
                }
                resolve(url);
            });
        });
    }

    private getShareableForce(force: Force): CompressedForce {
        let compressNull = (val: any) => {
            if (val === null || val === undefined) {
                return -1;
            }
            return val;
        };
        const f: CompressedForce = {
            n: force.name,
            u: force.units.map((u) => {
                let id = u.unitTemplate.shortId;
                let opts = u.selectedOptions;
                let v = compressNull(u.variantId);
                let p = compressNull(u.platoon);
                let n = compressNull(u.customName);
                return [id, opts, v, p, n];
            }),
            f: force.faction,
            g: force.gameId
        };
        return f;
    }

    public showApp() {
        this.debug.log('Removing QR Code Preview.');
        window.document.querySelector('ion-app').style.display = null;
        window.document.body.classList.remove('camera');
        window.document.getElementById('qrHelper').remove();
        window.document.getElementById('qrDismiss').remove();
        this.debug.log('Removed QR Code Preview.');
    }

    public hideApp() {
        this.debug.log('Showing QR Code Preview.');
        window.document.querySelector('ion-app').style.display = 'none';
        window.document.body.classList.add('camera');
        const helper = document.createElement('div');
        helper.id = 'qrHelper';
        const helpText = document.createTextNode('Share a force from another Warlord app and scan it here.');
        helper.appendChild(helpText);
        document.body.appendChild(helper);

        const button = document.createElement('button');
        button.id = 'qrDismiss';
        const buttonText = document.createTextNode('Cancel');
        button.appendChild(buttonText);
        button.onclick = () => {
            this.showApp();
        };
        document.body.appendChild(button);
        this.debug.log('Showed QR Code Preview.');
    }

    scan = (): Observable<Partial<Force>> => {
        this.hideApp();
        return from(this.barcodeScanner.scan()).pipe(
            catchError((err) => {
                console.log('Error', err);
                return of(null);
            }),
            switchMap((barcodeData) => {
                console.log('Barcode data', barcodeData);
                return this.parseDataFromResult(barcodeData.text);
            })
        );
        // this.showApp();
        // if (status.authorized) {
        //     this.debug.log('Permission granted.');
        //     return this.getForceDataFromScanner();
        // } else if (status.denied) {
        //     this.debug.log('Permission denied.');
        // } else {
        //     this.debug.log('Permission temporarily denied.');
        //     // permission was denied, but not permanently. You can ask for permission again at a later time.
        // }
        // return of(null);
        // }),
        // catchError((e: any) => {
        //     this.showApp();
        //     console.error(e);
        //     return of(null);
        // })
        // );
    };

    private async getForceDataFromScanner() {
        let res = await this.barcodeScanner.scan();
        this.showApp();
        return this.parseDataFromResult(res.text);
    }

    private parseDataFromResult(text): Observable<Partial<Force>> {
        return this.unitTemplateLibrary.units$.pipe(
            map((unitTemplates) => {
                let decompressNull = (val: any, nullVal) => {
                    if (val === -1) {
                        return nullVal;
                    }
                    return val;
                };
                try {
                    let f = JSON.parse(text);
                    return {
                        name: f.n,
                        faction: f.f,
                        gameId: f.g,
                        units: f.u
                            .map((u) => {
                                let unitTemplate = unitTemplates.find((ut) => ut.shortId === u[0]);
                                if (!unitTemplate) {
                                    console.error('Unit template not found with shortId ' + u[0]);
                                    return null;
                                }
                                return {
                                    unitTemplateId: unitTemplate._id,
                                    selectedOptions: u[1],
                                    variantId: decompressNull(u[2], null),
                                    platoon: decompressNull(u[3], null),
                                    customName: decompressNull(u[4], '')
                                };
                            })
                            .filter((x) => x)
                    };
                } catch (error) {
                    this.alertController
                        .create({
                            header: 'Error loading data.',
                            message: "This doesn't look like a Warlord QR Code.",
                            buttons: [
                                {
                                    text: 'Close'
                                }
                            ]
                        })
                        .then((alert) => alert.present());
                    return null;
                }
            })
        );
    }
}

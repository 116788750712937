import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { ForceUtils } from '../force.utils';
import { Force, Unit, UnitTemplate } from '../models';

@Pipe({
    name: 'unitCost'
})
export class GetCostPipe implements PipeTransform {
    constructor(private forceUtils: ForceUtils) {}

    transform(unit: Unit | UnitTemplate, force?: Force, dataBucket?: any): any {
        return this.forceUtils.getUnitCost(unit, force, dataBucket);
    }
}

@Pipe({
    name: 'itemCost'
})
export class ItemCostPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(itemCost: any): any {
        if (!itemCost) {
            return this.translateService.get('GLOBAL.FREE');
        }
        return this.translateService.get(['GLOBAL.PTS', 'GLOBAL.PT']).pipe(
            map((res) => {
                if (itemCost == 1) {
                    return `${itemCost}${res['GLOBAL.PT']}`;
                }
                return `${itemCost}${res['GLOBAL.PTS']}`;
            })
        );
    }
}

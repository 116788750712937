import { TranslateService } from '@ngx-translate/core';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { map, mergeAll, reduce, switchMap } from 'rxjs/operators';
import { InputAlert } from '../global/input-alert/input-alert';
import { snapshot } from '../global/utils';
import { Force, Unit, UnitTemplate, ForceType, ValidationError, UnitOption } from './models';

export abstract class ForceUtils {
    abstract gameId: string;
    protected translateService?: TranslateService;
    protected inputAlert?: InputAlert;

    getForceCost(force: Force): Observable<number> {
        return forkJoin(force.units.map((unit) => this.getUnitCost(unit, force))).pipe(
            mergeAll(),
            reduce((total, cost) => total + cost, 0)
        );
    }

    attachCostToForce(f: Force) {
        return f.error
            ? of(f)
            : this.getForceCost(f).pipe(
                  map(
                      (cost) =>
                          ({
                              ...f,
                              cost
                          } as Force)
                  )
              );
    }

    getDefaultForce(forceType?: ForceType): Partial<Force> {
        return { faction: forceType.faction, name: '', units: [], cost: 0 };
    }

    validateForce(force: Force): Observable<ValidationError[]> {
        return of([]);
    }

    getForceFromServer(forceId: string): Observable<Force> {
        throw new Error('getForceFromServer not implemented');
    }

    renamePlatoon(force: Force, platoonIndex: number): Promise<Force> {
        return new Promise((resolve, _reject) => {
            snapshot(
                combineLatest([
                    this.translateService.get('FORCES.DIALOGS.PLATOON_NAME.TITLE'),
                    this.translateService.get('FORCES.DIALOGS.PLATOON_NAME.MESSAGE')
                ]),
                ([title, message]) => {
                    this.inputAlert.show(title, message, force.platoons[platoonIndex] || '').then((val) => {
                        if (!val) {
                            return;
                        }
                        let platoons = [...force.platoons];
                        platoons[platoonIndex] = val;
                        force.platoons = platoons;
                        resolve(force);
                    });
                }
            );
        });
    }

    abstract getUnitCost(unit: Unit | UnitTemplate, force?: Force, dataBucket?: any): Observable<number>;
    abstract createUnitFromTemplate(unitTemplate: UnitTemplate, dataBucket?: any): Unit;
    abstract processForce(force: Force): Observable<Force>;
    abstract canUnitReceiveUpgrade(
        unit: Unit,
        force: Force,
        upgrade: UnitOption
    ): { canReceiveUpgrade: boolean; errorMessage?: { headerKey: string; messageKey: string } };

    preProcessForce(force: Force, unitTemplates: UnitTemplate[], forceId: string): Force {
        return {
            ...structuredClone(force),
            validationErrors: [],
            units: force.units.map(this.attachUnitTemplate(unitTemplates)).filter((u) => !!u),
            selected: force.id === forceId
        };
    }

    postProcessForce(force: Force) {
        return this.attachCostToForce(force).pipe(
            switchMap((f) => {
                return f.error
                    ? of(f)
                    : this.validateForce(f).pipe(
                          map(
                              (validationErrors) =>
                                  ({
                                      ...f,
                                      validationErrors
                                  } as Force)
                          )
                      );
            })
        );
    }

    attachUnitTemplate(unitTemplates: UnitTemplate[]) {
        return (unit) => {
            const unitTemplate = unitTemplates.find((u) => u._id === unit.unitTemplateId);
            if (!unitTemplate) {
                return null;
            }

            return {
                ...unit,
                unitTemplate
            };
        };
    }
}

import { Action, createReducer, on } from '@ngrx/store';
import { Force } from '../models';
import { addForce, addPointsAdjustment, deleteForce, loadAllForces, removePointsAdjustment, updateForce, upsertForce } from './actions';

export type EntityChangeState = 'Clean' | 'Dirty' | 'Error' | 'Deleted';
export interface ForceState {
    forces: Force[];
}

export const initialForceState: ForceState = {
    forces: []
};

export const forceReducer = createReducer(
    initialForceState,
    on(loadAllForces, (state, { forces }) => {
        return {
            ...state,
            forces
        };
    }),
    on(addForce, (state, { force }) => {
        return {
            ...state,
            forces: [...state.forces, force]
        };
    }),
    on(deleteForce, (state, { id }) => {
        return {
            ...state,
            forces: state.forces.filter((f) => f.id !== id)
        };
    }),
    on(updateForce, (state, { force }) => {
        return {
            ...state,
            forces: state.forces.map((f) => {
                if (force.id !== f.id) {
                    return f;
                }

                return { ...f, ...force };
            })
        };
    }),
    on(upsertForce, (state, { force }) => {
        const forces = state.forces.map((f) => {
            if (force.id !== f.id) {
                return f;
            }

            return force;
        });

        if (!forces.find((f) => f.id === force.id)) {
            forces.push(force);
        }

        return {
            ...state,
            forces
        };
    }),
    on(addPointsAdjustment, (state, { forceId, unitId, pointsAdjustment }) => {
        const forces = state.forces.map((f) => {
            if (forceId !== f.id) {
                return f;
            }

            if (unitId === undefined) {
                return { ...f, pointsAdjustments: [...(f.pointsAdjustments || []), pointsAdjustment] };
            } else {
                return {
                    ...f,
                    units: f.units.map((u) => {
                        if (unitId !== u.id) {
                            return u;
                        }

                        return {
                            ...u,
                            pointsAdjustments: [...(u.pointsAdjustments || []), pointsAdjustment]
                        };
                    })
                };
            }
        });

        return {
            ...state,
            forces
        };
    }),
    on(removePointsAdjustment, (state, { forceId, unitId, index }) => {
        const forces = state.forces.map((f) => {
            if (forceId !== f.id) {
                return f;
            }

            if (unitId === undefined) {
                let adjustedForce = { ...f, pointsAdjustments: [...f.pointsAdjustments] };
                adjustedForce.pointsAdjustments.splice(index, 1);
                return adjustedForce;
            } else {
                return {
                    ...f,
                    units: f.units.map((u) => {
                        if (unitId !== u.id) {
                            return u;
                        }

                        let adjustedUnit = { ...u, pointsAdjustments: [...u.pointsAdjustments] };
                        adjustedUnit.pointsAdjustments.splice(index, 1);
                        return adjustedUnit;
                    })
                };
            }
        });

        return {
            ...state,
            forces
        };
    })
);

export interface GameSpecificForceReducer {
    gameId: string;
    reducer: Function;
}
export const CUSTOM_FORCE_REDUCERS: { [gameId: string]: GameSpecificForceReducer } = {};
export const registerForceReducer = (gameSpecificReducer: GameSpecificForceReducer) => {
    console.log('Registering force reducer for ' + gameSpecificReducer.gameId);
    CUSTOM_FORCE_REDUCERS[gameSpecificReducer.gameId] = gameSpecificReducer;
};

export const getCombinedForceReducers = () => {
    return (state, action: Action) => {
        let newState = forceReducer(state, action);
        for (let r of Object.values(CUSTOM_FORCE_REDUCERS)) {
            newState = r.reducer(newState, action);
        }
        return newState;
    };
};

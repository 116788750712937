import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { Device, DEVICE$ } from './device';
import { Product } from './purchase/model';

export abstract class ArmyBuilderConfig {
    abstract version: string;
    abstract apiBaseUrl: string;
    paths = {
        statusEffectIcons: 'images/icons/statusEffects/'
    };
    partitionUnitsByFaction = true;
    supportedLanguages: string[];
    globalRequestHeaders: any;
    roles: { [key: string]: string };
    abstract settingsKeys: string[];
    abstract products?: Product[];
    abstract purchaseKey?: string;
    abstract purchaseKeys?: {
        apple?: string;
        google?: string;
        stripe?: string;
    };
    enableQRCode?: boolean;
    device$: Observable<Device> = DEVICE$;
    enableStripePurchases?: boolean;
}

export const ARMY_BUILDER_CONFIG: InjectionToken<ArmyBuilderConfig> = new InjectionToken<ArmyBuilderConfig>('Army Builder Config Token');

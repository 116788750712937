import { ArmyBuilderConfig, DEVICE$, GLOBAL_API_REQUEST_HEADERS } from 'army-builder-shared';
import { environment } from '../environments/environment';
import { API_REQUEST_HEADERS } from './global/request-headers';
import { PRODUCTS } from './subscription/products';

export const SUPPORTED_LANGUAGES = ['en'];

export class WarlordConfig implements ArmyBuilderConfig {
    apiBaseUrl: string = environment.apiBaseUrl;
    globalRequestHeaders: any = {
        ...GLOBAL_API_REQUEST_HEADERS,
        ...API_REQUEST_HEADERS
    };
    roles = {
        admin: 'app_admin',
        user: 'app_user'
    };
    version: string = environment.appVersion;
    paths: { statusEffectIcons: string };
    partitionUnitsByFaction = false;
    supportedLanguages = SUPPORTED_LANGUAGES;
    settingsKeys = ['show_force_cost_on_unit_page', 'bolt_action_enable_skills'];
    products = PRODUCTS;
    purchaseKey = 'wEilLpUTQQwzvgtjQEoJeOiXUmCxKodJ';
    subscriptionEnabled = false;
    device$ = DEVICE$;
}

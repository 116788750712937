import { Component, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AlertService } from '../../../global/alert/alert';
import { ArmyBuilderConfig } from '../../../global/config';
import { InputAlert } from '../../../global/input-alert/input-alert';
import { UnitTemplateLibrary } from '../../../global/unit-library';
import { UnitOptionLibrary } from '../../../global/unit-option-library';
import { ForceService } from '../../force.service';
import { ForceUtils } from '../../force.utils';
import { Force, Unit } from '../../models';

@Component({
    selector: 'abs-unit-details-page',
    template: `
        <abs-page-layout
            *ngIf="forceService.unit$ | async as unit"
            (titleClicked)="renameUnit(unit)"
            [title]="unit.name"
            [subTitle]="unit.cost + ('GLOBAL.PTS' | translate)"
        >
            <div class="content">
                <abs-unit-details [unit]="unit"></abs-unit-details>
            </div>
        </abs-page-layout>
    `
})
export class UnitDetailsPage {
    constructor(
        public forceService: ForceService,
        public unitLibrary: UnitTemplateLibrary,
        public UnitOptionLibrary: UnitOptionLibrary,
        public route: ActivatedRoute,
        public router: Router,
        public config: ArmyBuilderConfig,
        public alertService: AlertService,
        public inputAlert: InputAlert,
        public forceUtils: ForceUtils,
        public modalController: ModalController
    ) {}

    renameUnit(_unit: Unit) {
        // this.inputAlert.show(this.config.renameForceDialog.title, this.config.renameForceDialog.message, unit.name, true).then(name => {
        //   if (!name) {
        //     return;
        //   }
        //   this.forceService.update({
        //     id: unit.id,
        //     name
        //   });
        // });
    }
}

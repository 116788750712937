import { Platform } from '@ionic/angular';
// import { InAppPurchase2, IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { Product } from '../model';
import { Store } from '@ngrx/store';
import {
    AddPurchase,
    RemovePurchase,
    AddProduct,
    SetProductsStatus,
    PurchasesLoaded,
    PurchasesLoading,
    selectProducts,
    selectPurchases,
    selectRestoringPurchases,
    selectStatus
} from './state';
import { Injectable, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Purchases, PurchasesPackage } from '@awesome-cordova-plugins/purchases/ngx';
import { snapshot } from '../../utils';
import { DebugService, Logger } from '../../debug/debug.service';
import { ArmyBuilderConfig } from '../../config';
import { SettingsService } from '../../settings';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { HttpClientWithInFlightCache } from '../../httpClient';

export const PURCHASE_STATE = {
    loading: 'loading',
    loaded: 'loaded',
    error: 'error'
};

@Injectable()
export class PurchasingService implements OnDestroy {
    private configured = false;

    public products$: Observable<Product[]> = this.store.select(selectProducts);
    public products: Product[] = [];
    public purchases$: Observable<string[]> = this.store.select(selectPurchases);
    public status$: Observable<string[]> = this.store.select(selectStatus);
    public restoringPurchases$: Observable<boolean> = this.store.select(selectRestoringPurchases);

    private userPurchases: string[];

    private storageSub: Subscription;
    private purchasesSub: Subscription;
    private productsSub: Subscription;
    private purchaseConfigSub: Subscription;
    private debug: Logger;

    constructor(
        public platform: Platform,
        private store: Store,
        private purchases: Purchases,
        debugService: DebugService,
        private config: ArmyBuilderConfig,
        private http: HttpClientWithInFlightCache,
        private settingsService: SettingsService,
        @Optional() @SkipSelf() parent?: PurchasingService
    ) {
        if (parent) {
            throw Error(`[PurchasingService]: trying to create multiple instances, but this service should be a singleton.`);
        }
        this.purchasesSub = this.purchases$.subscribe((purchases) => {
            this.userPurchases = purchases;
        });

        this.productsSub = this.products$.subscribe((products) => {
            this.products = products;
        });

        this.debug = debugService.getInstance('[Purchasing Service]');
        this.init();
    }

    ngOnDestroy(): void {
        this.storageSub.unsubscribe();
        this.purchasesSub.unsubscribe();
        this.productsSub.unsubscribe();
        if (this.purchaseConfigSub) {
            this.purchaseConfigSub.unsubscribe();
        }
    }

    async init() {
        await this.configurePurchasing();
    }

    private getProductFromRCProduct(pkg: PurchasesPackage, productList: Product[]): Product {
        return {
            ...productList.find((p) => p.key === pkg.identifier),
            price: pkg.product.priceString,
            package: pkg
        };
    }

    configurePurchasing() {
        return new Promise((resolve, reject) => {
            if (this.configured) {
                return;
            }

            this.configured = true;

            this.log('configurePurchasing');
            let productsURL = this.config.apiBaseUrl + '/library/global/products?getAll=1';
            this.log('configurePurchasing: ' + productsURL);
            this.platform.ready().then(() => {
                this.log('configurePurchasing: platform ready');
                this.purchaseConfigSub = this.settingsService.login$
                    .pipe(
                        filter((l) => l.user),
                        tap((l) => this.log(`configurePurchasing: user logged in as ${l.user.id}`)),
                        withLatestFrom(
                            this.http.get(productsURL, { requiresLogin: true }).pipe(
                                map((res) => res.items as Product[]),
                                tap((p) => this.log(`configurePurchasing: ${p.length} products found`))
                            )
                        )
                    )
                    .subscribe(([login, apiProducts]) => {
                        console.log('configurePurchasing: Products loaded:', apiProducts);

                        this.purchases.setDebugLogsEnabled(true);

                        let apiKey: string;
                        apiProducts.forEach((product) => {
                            // Add the products to the store, even though we don't have the
                            // RC package details yet.  The current product data is needed
                            // to list the products on the purchase page anyway, and package
                            // info isn't needed yet.
                            this.store.dispatch(AddProduct({ product }));
                        });

                        this.log('configurePurchasing: platform list:', this.platform.platforms());
                        if (!this.platform.is('cordova')) {
                            this.log('configurePurchasing: not cordova');
                            return;
                        } else if (this.platform.is('android')) {
                            this.log('configurePurchasing: android');
                            apiKey = this.config.purchaseKeys.google;
                        } else if (
                            this.platform.is('ios') ||
                            this.platform.is('desktop') // Designed for Mac hybrid app
                        ) {
                            this.log('configurePurchasing: ios');
                            apiKey = this.config.purchaseKeys.apple;
                        } else {
                            this.log('configurePurchasing: web?');
                            apiKey = this.config.purchaseKeys.stripe;
                        }

                        this.log('configurePurchasing: Configuring products');
                        this.purchases.setDebugLogsEnabled(true); // Enable to get debug logs
                        this.purchases.configure(apiKey, login.user.id); // Don't use configureWith until it is fixed: https://community.revenuecat.com/sdks-51/ionic-cordova-there-is-no-singleton-instance-make-sure-you-configure-purchases-before-trying-to-get-the-default-instance-2668

                        this.log('configurePurchasing: Configured');

                        setTimeout(() => {
                            this.purchases.getOfferings().then(
                                (offerings) => {
                                    this.log('configurePurchasing: ', { offerings });
                                    let packages = offerings?.current?.availablePackages;
                                    this.log('configurePurchasing: ', { packages });
                                    if (packages?.length > 0) {
                                        // Display packages for sale
                                        packages.forEach((pkg) => {
                                            this.log('configurePurchasing: Registering ' + pkg.identifier);
                                            this.store.dispatch(AddProduct({ product: this.getProductFromRCProduct(pkg, apiProducts) }));
                                        });
                                    }

                                    setInterval(() => {
                                        this.refreshUserPurchases();
                                    }, 60000);
                                    this.refreshUserPurchases();
                                    resolve(null);
                                },
                                (error) => {
                                    this.debug.log('Error getting offerings: ', error);
                                    console.log('Error getting offerings: ', error);

                                    this.configured = false;
                                    reject(null);
                                }
                            );
                        }, 1000);
                    });
            });
        });
    }

    private addPurchase = (productId: string) => {
        this.debug.log('addPurchase: ' + productId);
        this.refreshUserPurchases();
        this.store.dispatch(SetProductsStatus({ productId, status: 'loaded' }));
    };

    async purchase(productId) {
        if (!this.platform.is('cordova')) {
            if (this.config.enableStripePurchases) {
                let product = this.products.find((p) => p.key === productId);
                this.settingsService.login$
                    .pipe(
                        switchMap((login) =>
                            this.http.post(
                                this.config.apiBaseUrl + '/purchase/createCheckoutSession/',
                                {
                                    priceId: product.stripeKey
                                },
                                {
                                    headers: this.config.globalRequestHeaders,
                                    withCredentials: true
                                }
                            )
                        )
                    )
                    .subscribe((res) => {
                        console.log(res);
                        // TODO: does this need a check of some sort?
                        // this.addPurchase(productId);
                    });
            } else {
                console.log('Purchases not supported on this platform.');
                this.store.dispatch(AddPurchase({ productId }));
            }

            return;
        }
        this.store.dispatch(SetProductsStatus({ productId, status: 'loading' }));
        snapshot(this.products$, (products) => {
            let pkg = products.find((p) => p.key === productId).package;

            this.debug.log('Ordering From Store: ' + productId, JSON.stringify(pkg));
            this.purchases
                .purchasePackage(pkg)
                .then(({ productIdentifier, customerInfo }) => {
                    this.debug.log(JSON.stringify({ productId, pkg, productIdentifier, customerInfo }));
                    if (typeof customerInfo.entitlements.active[productId] !== 'undefined') {
                        this.addPurchase(productId);
                        this.debug.log(`${productId} added`);
                    }
                })
                .catch(({ error, userCancelled }) => {
                    // Error making purchase
                    console.log(error, userCancelled);
                    this.debug.log('Error during purchase: ' + JSON.stringify({ error, userCancelled }));
                });
        });
    }

    refreshUserPurchases() {
        this.log('refreshUserPurchases');
        this.purchases.getCustomerInfo().then((customerInfo) => {
            this.log('refreshUserPurchases', { customerInfo });
            const productIds = this.products.map((p) => p.key);
            const entitlements = Object.values(customerInfo.entitlements.active).map((e) => e.identifier);
            productIds.forEach((productId) => {
                if (this.userPurchases.includes(productId) && !entitlements.includes(productId)) {
                    this.log('Removing purchase from app state: ', productId);
                    this.store.dispatch(RemovePurchase({ productId }));
                } else if (!this.userPurchases.includes(productId) && entitlements.includes(productId)) {
                    this.log('Adding purchase to app state: ', productId);
                    this.store.dispatch(AddPurchase({ productId }));
                }
            });

            this.userPurchases = entitlements;
        });
    }

    isOwned(productId: string): boolean {
        let purchaseKey = productId.toLowerCase();
        if (purchaseKey === 'core') {
            return true;
        }

        // if (!this.platform.is('cordova')) {
        //     return true;
        // }

        return this.userPurchases.includes('all_access') || this.userPurchases.includes(purchaseKey);
    }

    private addOwnedPurchases() {
        this.store.dispatch(PurchasesLoading());
        this.purchases.syncPurchases();

        this.purchases.restorePurchases();
        this.refreshUserPurchases();
        this.store.dispatch(PurchasesLoaded());
    }

    async restorePurchases() {
        console.log('restorePurchases', { configured: this.configured });
        if (!this.configured) {
            await this.configurePurchasing();
        }
        this.addOwnedPurchases();
    }

    log(...args: any[]) {
        this.debug.log(...args);
        console.log(...args);
    }

    isItemAvailable(item: any): boolean {
        if (!item.packs) {
            return false;
        }
        for (const pack of item.packs) {
            if (this.isOwned(pack)) {
                return true;
            }
        }
        return false;
    }
}

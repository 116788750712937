import { EntityCollectionDataService, Logger, QueryParams } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { from, Observable, of } from 'rxjs';
import { switchMap, map, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { markEntityAsDirty, markEntityAsDeleted } from './actions';
import { NativeStorageService } from './native-storage.service';

@Injectable()
export abstract class LocalStorageDataService<T> {
    abstract name: string;
    entities: T[] = [];
    getAll$ = of(0).pipe(
        map(() => this.name),
        switchMap((name) =>
            from(
                this.storage.getItem(name, []).then((entities: T[]) => {
                    this.entities = entities;
                    return entities;
                })
            )
        ),
        distinctUntilChanged(),
        shareReplay(1)
    );

    constructor(private logger: Logger, private storage: NativeStorageService, private store: Store<any>) {}

    add(entity: T): Observable<T> {
        this.logger.log('Storage add: ' + this.name);
        let newId = (entity as any).id || ('' + Date.now() + Math.floor(Math.random() * 1000)).toString();

        entity = { ...entity, id: newId };
        this.entities = [...this.entities, entity];
        this.updateStorage();
        return of(entity);
    }

    delete(id: string): Observable<string> {
        this.entities = this.entities.filter((e) => (e as any).id !== id);
        this.updateStorage();
        this.store.dispatch(markEntityAsDeleted({ entityType: this.name, entityId: id }));
        return of(id);
    }

    getAll(): Observable<T[]> {
        return this.getAll$;
    }

    getById(id: any): Observable<T> {
        throw new Error('Method not implemented.');
    }

    getWithQuery(params: string | QueryParams): Observable<T[]> {
        throw new Error('Method not implemented.');
    }

    update(update: Update<T>): Observable<T> {
        let updatedEntity = null;
        this.entities = this.entities.map((e, i) => {
            if ((e as any).id === update.id) {
                updatedEntity = {
                    ...e,
                    ...update.changes
                };
                return updatedEntity;
            }
            return e;
        });
        if (!updatedEntity) {
            console.error('No updated entity found.  Did you mean to use add instead of update?');
        }
        this.updateStorage();
        this.store.dispatch(markEntityAsDirty({ entityType: this.name, entityId: updatedEntity.id }));
        return of(updatedEntity);
    }

    upsert(entity: T): Observable<T> {
        let entityToUpsert = entity as any;
        if (this.entities.find((e) => (e as any).id === entityToUpsert.id)) {
            this.update({ id: entityToUpsert.id, changes: entityToUpsert });
        } else {
            this.add(entityToUpsert);
        }

        this.store.dispatch(markEntityAsDirty({ entityType: this.name, entityId: entityToUpsert.id }));
        return of(entityToUpsert);
    }

    private updateStorage() {
        this.storage.setItem(this.name, this.entities);
    }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ForceService } from '../../force.service';
import { ForceUtils } from '../../force.utils';
import { Force } from '../../models';

@Component({
    selector: 'abs-platoon-select',
    template: `<ion-button (click)="prev()" [disabled]="platoonId === 0">&lt;</ion-button>
        <div class="label" (click)="changePlatoonName()">
            {{ force.platoons[platoonId] || ('FORCES.PLATOON_PREFIX' | translate) + (platoonId + 1) }}
        </div>

        <ion-button (click)="next()">&gt;</ion-button> `,
    styles: [
        `
            :host {
                display: flex;
                width: 100%;
                align-items: center;
            }
            .label {
                flex: 1 1 auto;
                text-align: center;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlatoonSelectComponent {
    @Input()
    platoonId: number;

    @Input()
    force: Force;

    @Output()
    platoonChanged = new EventEmitter<number>();

    constructor(private forceUtils: ForceUtils, private forceService: ForceService) {}

    next() {
        this.platoonChanged.emit(this.platoonId + 1);
    }

    prev() {
        this.platoonChanged.emit(Math.max(this.platoonId - 1, 0));
    }

    changePlatoonName() {
        this.forceUtils.renamePlatoon(this.force, this.platoonId).then((force) => {
            this.forceService.update(force);
        });
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, from } from 'rxjs';
import { ArmyBuilderConfig } from '../../config';
import { HttpClientWithInFlightCache } from '../../httpClient';
import { InputAlert } from '../../input-alert/input-alert';
import { Modal } from '../../modal';

@Component({
    selector: 'abs-devices',
    template: `
        <ng-container
            *ngLet="{
                devices: devices$ | async,
                device: device$ | async
            } as props"
        >
            <h2>{{ 'GLOBAL.SETTINGS.DEVICES.TITLE' | translate }}</h2>
            <p>{{ 'GLOBAL.SETTINGS.DEVICES.DESCRIPTION' | translate }}</p>
            <p>
                <ion-button (click)="clear()">{{ 'GLOBAL.SETTINGS.DEVICES.CLEAR_DEVICES' | translate }}</ion-button>
            </p>
            <ng-template #deviceTemplate let-item="item">
                <div class="itemWrap">
                    <div class="itemName">
                        {{ item?.friendlyName || item?.id }}
                        <ng-container *ngIf="props.device.id === item.id">
                            ({{ 'GLOBAL.SETTINGS.DEVICES.THIS_DEVICE' | translate }})</ng-container
                        >
                    </div>
                    <div class="itemDescription">
                        {{ item.lastActivity | relativeDate }}
                    </div>
                </div>
                <div class="itemCost">
                    <ion-icon name="log-out-outline" (click)="logout($event, item.id)"></ion-icon>
                </div>
            </ng-template>
            <abs-list [items]="props.devices" [template]="deviceTemplate" (itemSelect)="showDeviceNameModal($event.item)"></abs-list>

            <ng-template #deviceName let-data="data">
                <p>{{ 'GLOBAL.SETTINGS.DEVICES.DEVICENAME.DESCRIPTION' | translate }}</p>
                <ion-item>
                    <ion-input #deviceName></ion-input>
                </ion-item>
            </ng-template>
        </ng-container>
    `
})
export class DevicesComponent {
    devicesUrl = this.config.apiBaseUrl + '/user/devices';
    devices$ = new BehaviorSubject<any[]>([]);
    device$ = this.config.device$;

    @ViewChild('deviceName')
    deviceName: any;

    constructor(
        private config: ArmyBuilderConfig,
        private httpClient: HttpClientWithInFlightCache,
        private alertController: AlertController,
        private translateService: TranslateService,
        private modal: Modal,
        private inputAlert: InputAlert
    ) {
        this.refreshDevices();
    }

    logout(e, deviceId) {
        e.stopPropagation();
        let translate = (key) => this.translateService.instant(key);
        let header = translate('GLOBAL.SETTINGS.DEVICES.LOGOUT_WARNING.TITLE');
        let message = translate('GLOBAL.SETTINGS.DEVICES.LOGOUT_WARNING.DESCRIPTION');
        let yes = translate('GLOBAL.YES');
        let no = translate('GLOBAL.NO');
        this.alertController
            .create({
                header,
                message,
                buttons: [
                    {
                        text: no,
                        role: 'cancel',
                        cssClass: 'alertButton'
                    },
                    {
                        text: yes,
                        cssClass: 'alertButton',
                        role: 'confirm',
                        handler: () => {
                            this.httpClient.delete(this.devicesUrl + '/' + deviceId).subscribe((res) => {
                                console.log('Device deleted.  Refreshing device list.');
                                this.refreshDevices();
                            });
                        }
                    }
                ]
            })
            .then((x) => x.present());
    }

    refreshDevices() {
        this.httpClient.get(this.devicesUrl).subscribe((res) => {
            console.log('Devices:', res);
            if (res) {
                this.devices$.next(res);
            }
        });
    }

    showDeviceNameModal(device) {
        this.inputAlert
            .show(
                this.translateService.instant('GLOBAL.SETTINGS.DEVICES.DEVICE_NAME.TITLE'),
                this.translateService.instant('GLOBAL.SETTINGS.DEVICES.DEVICE_NAME.DESCRIPTION'),
                device.friendlyName,
                'text',
                true
            )
            .then((res) => {
                if (!res) {
                    return;
                }
                this.httpClient
                    .put(`${this.devicesUrl}/${device.id}`, {
                        friendlyName: res
                    })
                    .subscribe(() => {
                        this.refreshDevices();
                    });
            });
    }

    clear() {
        let translate = (key) => this.translateService.instant(key);
        let header = translate('GLOBAL.SETTINGS.DEVICES.CLEAR.TITLE');
        let message = translate('GLOBAL.SETTINGS.DEVICES.CLEAR.DESCRIPTION');
        let yes = translate('GLOBAL.YES');
        let no = translate('GLOBAL.NO');
        this.alertController
            .create({
                header,
                message,
                buttons: [
                    {
                        text: no,
                        role: 'cancel',
                        cssClass: 'alertButton'
                    },
                    {
                        text: yes,
                        cssClass: 'alertButton',
                        role: 'confirm',
                        handler: () => {
                            this.httpClient.delete(this.devicesUrl, { requiresLogin: true }).subscribe((res) => {
                                console.log('Devices cleared.  Refreshing device list.');
                                this.refreshDevices();
                            });
                        }
                    }
                ]
            })
            .then((x) => x.present());
    }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonInput } from '@ionic/angular';
import { QRCodeService } from '../../../global/qr-code-service';

@Component({
    selector: 'abs-import-force',
    template: `
        <form (submit)="redirectToSharedForce()">
            <p>{{ 'GLOBAL.SHARE.IMPORT.DESCRIPTION' | translate }}</p>
            <div *ngIf="enableQRCode">
                <ion-button (click)="importByQRCode()">{{ 'GLOBAL.SHARE.QR_CODE.IMPORT' | translate }}</ion-button>
            </div>
            <div class="form-row">
                <ion-input #importCode className="alert-input-wrapper sc-ion-alert-md"></ion-input>
                <ion-button class="small" (click)="redirectToSharedForce()"><ion-icon name="cloud-download-outline"></ion-icon></ion-button>
            </div>
            <p class="error" *ngIf="importError">
                {{ 'GLOBAL.SHARE.IMPORT.ERRORS.INVALID_CODE' | translate }}
            </p>
        </form>
    `,
    styles: [
        `
            ion-button.small {
                flex: 0 0 auto;
            }

            .form-row {
                display: flex;
            }
        `
    ]
})
export class ImportForceComponent {
    @Input()
    redirectPrefix: string[] = [];

    @Input()
    enableQRCode: boolean;

    @ViewChild('importCode')
    importCode: IonInput;
    importError = false;

    @Output()
    redirect = new EventEmitter<void>();

    constructor(private router: Router, private route: ActivatedRoute, private qrService: QRCodeService) {}

    redirectToSharedForce() {
        try {
            this.importError = false;
            let code = '' + this.importCode.value;
            let forceId = parseInt(code, 36);
            if (isNaN(forceId) || !/^[A-Za-z0-9]{8,10}$/.test(code)) {
                throw new Error('Invalid code: ' + code);
            }
            this.redirect.emit();
            this.router.navigate([...this.redirectPrefix, forceId], { relativeTo: this.route });
        } catch (err) {
            console.error(err);
            this.importError = true;
        }
    }

    importByQRCode() {
        this.qrService.scan().subscribe((res) => {
            console.log({ res });
        });
    }
}

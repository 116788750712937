import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, of, BehaviorSubject } from 'rxjs';
import { take, map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { UserService } from '../global/userService';
import { HttpClientWithInFlightCache } from '../global/httpClient';
import { ArmyBuilderConfig } from '../global/config';
import { selectIssues } from './support.selectors';
import { loadIssuesComplete, markIssueAsRead } from './support.actions';
import { Modal } from '../global/modal';
import { SupportComponent } from './support.component';
import { IssueStatus, SupportIssue } from './model';

@Injectable()
export class SupportService {
    allIssues$: Observable<SupportIssue[]> = this.store.select(selectIssues);
    private _route$: Observable<any> = this.store.select((s: any) => s.router).pipe(distinctUntilChanged());

    public get route$(): Observable<any> {
        return this._route$;
    }
    public set route$(value: Observable<any>) {
        this._route$ = value;
    }

    unreadCount$ = new BehaviorSubject<number>(0);
    issueMetadata$ = combineLatest([this.route$, this.userService.login$]);
    constructor(
        private config: ArmyBuilderConfig,
        private store: Store,
        private userService: UserService,
        private httpClient: HttpClientWithInFlightCache,
        private modal: Modal
    ) {
        this.updateUnreadCount();
    }

    updateIssue(issueId: string, update: { comment: string; issueStatus: IssueStatus }, mode: string) {
        return this.userService.login$.pipe(
            take(1),
            map((userData) => ({
                description: update.comment,
                issueStatus: update.issueStatus,
                updatedBy: userData.user.name
            })),
            switchMap((report) => {
                if (mode === 'user') {
                    return this.httpClient.post(`${this.config.apiBaseUrl}/support/${issueId}/comment`, { comment: report.description });
                }
                return this.httpClient.put(`${this.config.apiBaseUrl}/support/${issueId}`, report);
            })
        );
    }

    loadIssues(statuses: IssueStatus[] = [], numDays = 28) {
        let res = this.httpClient.get(`${this.config.apiBaseUrl}/support?statuses=${statuses.join(',')}&numDays=${numDays}`);
        res.subscribe((issues) => {
            this.store.dispatch(loadIssuesComplete({ issues }));
        });

        return res;
    }

    showModal() {
        this.modal.show({
            component: SupportComponent
        });
    }

    submitReport(description: string) {
        return this.issueMetadata$.pipe(
            take(1),
            map(([r, userData]) => ({
                description,
                userAgent: navigator.userAgent,
                userData,
                url: r.state.url,
                appVersion: this.config.version
            })),
            switchMap((report) => this.httpClient.post(this.config.apiBaseUrl + '/support', report))
        );
    }

    markIssueRead(_id: string) {
        this.httpClient.put(`${this.config.apiBaseUrl}/support/${_id}/read`, null).subscribe((x) => {
            this.store.dispatch(markIssueAsRead({ issueId: _id }));
            this.updateUnreadCount();
        });
    }

    markIssueUnread(_id: string) {
        return this.httpClient.put(`${this.config.apiBaseUrl}/support/${_id}/unread`, null);
    }

    updateUnreadCount() {
        this.httpClient.get(`${this.config.apiBaseUrl}/support/unread`).subscribe((x) => this.unreadCount$.next(parseInt(x)));
    }
}

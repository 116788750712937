import { Store, select } from '@ngrx/store';
import { LanguageSelector, LoginSelector, LoginCheckedSelector } from './selectors';
import { selectLanguage, updateSettingsValue } from './actions';
import { Platform } from '@ionic/angular';
import { Inject, Injectable } from '@angular/core';
import { NativeStorageService } from '../../data/native-storage.service';
import { defaultLoginState, initialSettingsState } from './reducer';
import { markEntityAsDirty } from '../../data/actions';
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { LoginState } from '../models';
import { DEFAULT_SETTINGS_VALUE } from './default-settings-value';

@Injectable()
export class SettingsService {
    loginChecked$ = this.store.pipe(select(LoginCheckedSelector));
    login$: Observable<LoginState> = this.loginChecked$.pipe(
        distinctUntilChanged(),
        tap((x) => console.log({ loginChecked: x })),
        switchMap((isChecked) =>
            this.store.pipe(
                select(LoginSelector),
                map((l) => {
                    if (isChecked) {
                        return l;
                    }
                    return { ...defaultLoginState, error: l.error, loading: l.loading };
                })
            )
        ),
        shareReplay(1)
    );
    selectedLanguage$ = this.store.pipe(select(LanguageSelector));
    settings$ = this.login$.pipe(
        switchMap((_) => this.store.select((s) => s.settings)),
        distinctUntilChanged()
    );

    settingsSubscription: Subscription;

    constructor(
        private store: Store<any>,
        private storage: NativeStorageService,
        platform: Platform,
        @Inject(DEFAULT_SETTINGS_VALUE) private defaultSettingsValue = {}
    ) {
        platform.ready().then((_) => {
            this.load();

            if (!Capacitor.isNativePlatform())
                window.onstorage = async () => {
                    let newSettings = JSON.parse(window.localStorage.getItem('CapacitorStorage.settings'));
                    let currentSettings = await firstValueFrom(this.login$);

                    let tokens = {
                        newAccessToken: newSettings?.login?.user?.accessToken,
                        oldAccessToken: currentSettings?.user?.accessToken
                    };

                    this.load();
                };
        });
    }

    load() {
        this.storage.getItem('settings', initialSettingsState).then((value) => {
            let defaultSettingsStatus = {
                ...this.defaultSettingsValue,
                ...value
            };

            for (let key of Object.keys(defaultSettingsStatus)) {
                if (defaultSettingsStatus.hasOwnProperty(key)) {
                    this.dispatchNewValue(key, defaultSettingsStatus[key]);
                }
            }

            if (!this.settingsSubscription) {
                this.settingsSubscription = this.settings$.pipe(debounceTime(1000)).subscribe((s) => {
                    let newSettings = JSON.parse(window.localStorage.getItem('CapacitorStorage.settings'));

                    if (JSON.stringify(newSettings) === JSON.stringify(s)) {
                        console.log('Settings values are equal.  Skipping localStorage update.');
                        return;
                    }

                    this.storage.setItem('settings', {
                        ...defaultSettingsStatus,
                        ...s
                    });
                });
            }
        });
    }

    updateValue(key: string, value: any) {
        this.dispatchNewValue(key, value);
        this.markAsDirty();
    }

    dispatchNewValue(key: string, value: any) {
        this.store.dispatch(updateSettingsValue({ settingsKey: key, newValue: value }));
    }

    selectLanguage(language: string) {
        this.store.dispatch(selectLanguage({ language }));
        this.markAsDirty();
    }

    private markAsDirty() {
        this.store.dispatch(markEntityAsDirty({ entityType: 'settings' }));
    }
}

import { Injectable } from '@angular/core';
import { ArmyBuilderConfig, HttpClientWithInFlightCache, SettingsService } from 'army-builder-shared';
import { of } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
    public subStatus$ = this.settingsService.login$.pipe(
        filter((x) => x.user),
        switchMap((login) => {
            let user = login.user;
            if (user?.roles?.includes('app_admin')) {
                // return of('active');
            }

            return this.http
                .get(this.config.apiBaseUrl + '/user/subscription/status', {
                    responseType: 'text',
                    withCredentials: true,
                    requiresLogin: true
                })
                .pipe(
                    catchError((_err) => 'inactive'),
                    shareReplay(1)
                );
        })
    );

    public userHasAccess$ = this.subStatus$.pipe(map((s) => s === 'active'));

    constructor(private http: HttpClientWithInFlightCache, private settingsService: SettingsService, private config: ArmyBuilderConfig) {}
}

import { Component } from '@angular/core';
import { SettingsService } from '../service/settings.service';
import { ArmyBuilderConfig } from '../../config';

@Component({
    selector: 'abs-settings',
    template: `
        <ng-container *ngLet="{ settings: settings$ | async } as props">
            <abs-list [items]="settingKeys" [template]="settingsTemplate" [showDelete]="false"></abs-list>
            <ng-template #settingsTemplate let-item="item.name">
                <div class="{{ item }}">
                    <h2>{{ 'GLOBAL.SETTINGS.' + (item | uppercase) + '.TITLE' | translate }}</h2>
                    <p>{{ 'GLOBAL.SETTINGS.' + (item | uppercase) + '.DESCRIPTION' | translate }}</p>
                    <ion-toggle
                        [checked]="!!props.settings[item]"
                        (ionChange)="toggleChanged(item, $event)"
                    ></ion-toggle>
                </div>
            </ng-template>
        </ng-container>
    `
})
export class SettingsComponent {
    settingKeys = this.config.settingsKeys.map((key) => ({ name: key }));
    settings$ = this.settingsService.settings$;
    constructor(public settingsService: SettingsService, public config: ArmyBuilderConfig) {
        console.log(this.config.settingsKeys);
    }

    toggleChanged(key: string, event: any) {
        this.settingsService.updateValue(key, event.detail.checked);
    }
}

import { Component } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ArmyBuilderConfig, PurchasingService } from 'army-builder-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CancelSubscriptionComponent } from './cancel-subscription.component';
import { SubscriptionService } from './subscription.service';

@Component({
    selector: 'wl-manage-subscription',
    template: `
        <p class="content">{{ 'GLOBAL.SUBSCRIPTION.STATUS' | translate }}: {{ subStatus$ | async | capitalise }}</p>
        <form action="{{ config.apiBaseUrl }}/purchase/createPortalSession" method="POST" *ngIf="stripeCheckout">
            <div class="buttons">
                <ion-button type="submit"> {{ 'GLOBAL.SUBSCRIPTION.MANAGE' | translate }} </ion-button>
            </div>
        </form>
        <!-- <p>stripeCheckout: {{ stripeCheckout }}</p>
        <p>subscription$:</p>
        <pre>{{ subscription$ | async | json }}</pre> -->
        <ng-container *ngIf="!stripeCheckout">
            <div *ngIf="subscription$ | async as product" class="buttons">
                <ion-button color="danger" *ngIf="product.type === 'PAID_SUBSCRIPTION' && isOwned(product.key)" (click)="cancelSub()">
                    {{ 'GLOBAL.SUBSCRIPTION.CANCEL' | translate }}
                </ion-button>
            </div>
        </ng-container>
    `,
    styles: [
        `
            .content {
                margin-bottom: 0;
            }
        `
    ]
})
export class ManageSubscriptionComponent {
    subStatus$ = this.subscriptionService.subStatus$;
    stripeCheckout = !(this.platform.is('android') || this.platform.is('ios'));
    subscription$ = this.purchasingService.products$.pipe(map((p) => p[0]));
    status$: Observable<string[]> = this.purchasingService.status$;

    constructor(
        public config: ArmyBuilderConfig,
        private subscriptionService: SubscriptionService,
        private platform: Platform,
        private purchasingService: PurchasingService,
        private modalController: ModalController
    ) {}

    purchase(productId: string) {
        this.purchasingService.purchase(productId);
    }

    isOwned(productId: string) {
        return this.purchasingService.isOwned(productId);
    }

    async cancelSub() {
        let modal = await this.modalController.create({
            component: CancelSubscriptionComponent
        });

        modal.present();
    }
}

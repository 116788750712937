import { Product } from 'army-builder-shared';

export const PRODUCTS: Product[] = [
    {
        key: 'all_access',
        name: { en: 'All Access' },
        type: 'PAID_SUBSCRIPTION',
        description: { en: 'Full access to the app' },
        // stripeKey: 'price_1Jpr2fATXOvSQLYLieYcqgV9' // LIVE
        stripeKey: 'price_1Jq3G2F1UuO6HuE1vX9UlbKT' // TEST
    }
];

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule, Platform } from '@ionic/angular';
import { ListModule } from '../global/components/list.module';
import { PageLayoutModule } from '../global/page-layout';
import { BattlePage, AddForcePage, UnitStatusPage, GetIconPipe, StatusIndicatorComponent } from './components';
import { RouterModule } from '@angular/router';
import { BattleService } from './service/battle.service';
import { battleReducer, getCombinedBattleReducers, initialBattleState } from './service/reducer';
import { StoreModule } from '@ngrx/store';
import { StatusIndicatorsComponent } from './components/status-indicators/status-indicators.component';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageModule } from '../global/language';

export const BATTLE_COMPONENTS = [
    BattlePage,
    AddForcePage,
    UnitStatusPage,
    StatusIndicatorsComponent,
    StatusIndicatorComponent,
    GetIconPipe
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([]),
        IonicModule,
        ListModule,
        PageLayoutModule,
        StoreModule.forFeature('Battle', getCombinedBattleReducers()),
        TranslateModule,
        LanguageModule
    ],
    declarations: [...BATTLE_COMPONENTS],
    exports: [...BATTLE_COMPONENTS],
    providers: []
})
export class ArmyBuilderBattleModule {}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';

import { IonicModule, IonicRouteStrategy, Platform, ToastController } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
    ArmyBuilderConfig,
    ArmyBuilderSharedModule,
    ArmyBuilderStoreModule,
    SettingsModule,
    UserService,
    LanguageModule,
    SupportModule,
    pageTransition,
    ForceDataService,
    PurchaseModule,
    DEFAULT_SETTINGS_VALUE,
    SupportService,
    SettingsService
} from 'army-builder-shared';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { WarlordConfig } from './config';
import { HomePage } from './home/home.page';
import { GameListPage } from './home/game-list.page';
import { WarlordSettingsModule } from './settings/settings.module';
import { NgLetModule } from 'ng-let';
import { MenuModule } from './global/menu/menu.module';
import { WarlordUserService } from './settings/user/user.service';
import { ContentPage } from './home/content.page';
import { SubscriptionModule } from './subscription/subscription.module';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { PageLayoutModule } from './global/page-layout';
import { EntityDataService } from '@ngrx/data';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, indexedDBLocalPersistence, initializeAuth, provideAuth } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { Capacitor } from '@capacitor/core';
import { IonicStorageModule } from '@ionic/storage-angular';
import { debounce, debounceTime, filter, map, take, withLatestFrom } from 'rxjs';

@NgModule({
    declarations: [AppComponent, HomePage, GameListPage, ContentPage],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot({
            mode: 'md',
            navAnimation: pageTransition
        }),
        AppRoutingModule,
        MenuModule,
        ArmyBuilderStoreModule,
        ArmyBuilderSharedModule,
        TranslateModule,
        SettingsModule,
        WarlordSettingsModule,
        NgLetModule,
        PageLayoutModule,
        LanguageModule,
        SubscriptionModule,
        NgLetModule,
        SupportModule,
        IonicStorageModule.forRoot(),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        // provideAuth(() => getAuth())
        provideAuth(() => {
            if (Capacitor.isNativePlatform()) {
                return initializeAuth(getApp(), {
                    persistence: indexedDBLocalPersistence
                });
            } else {
                return getAuth();
            }
        }),
        PurchaseModule.forRoot()
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ArmyBuilderConfig, useClass: WarlordConfig },
        WarlordUserService,
        {
            provide: UserService,
            useExisting: WarlordUserService
        },
        Insomnia,
        {
            provide: DEFAULT_SETTINGS_VALUE,
            useValue: {
                // itemFilters: { item: { type: EQUIPMENT_TYPES } },
                // itemSort: {
                //     item: {}
                // },
                // disableGrouping: {
                //     item: {}
                // },
                // showIllegalItems: {
                //     item: {}
                // }
            }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        public platform: Platform,
        private insomnia: Insomnia,
        entityDataService: EntityDataService,
        forceDataService: ForceDataService,
        private supportService: SupportService,
        private settingsService: SettingsService,
        private toastController: ToastController,
        private router: Router
    ) {
        entityDataService.registerService('Force', forceDataService); // Prevents the default ngrx data handler trying to sync incorrectly if we are outside of a game module
        this.platform.ready().then(() => {
            this.insomnia.keepAwake().then(
                () => console.log('insomnia success'),
                (e) => console.log('insomnia error', e)
            );

            this.settingsService.login$
                .pipe(
                    map((l) => l?.user),
                    filter((user) => user),
                    debounceTime(500),
                    withLatestFrom(this.supportService.unreadCount$)
                )
                .subscribe(async ([_user, unreadCount]) => {
                    if (unreadCount > 0) {
                        let toast = await this.toastController.create({
                            message: `You have ${unreadCount} support tickets with new updates.`,
                            position: 'top',
                            color: 'warning',
                            layout: 'stacked',
                            buttons: [
                                {
                                    text: 'Go to support tickets',
                                    handler: () => {
                                        this.toastController.dismiss();
                                        this.router.navigateByUrl('/support');
                                    }
                                },
                                {
                                    text: 'Dismiss',
                                    handler: () => this.toastController.dismiss()
                                }
                            ]
                        });
                        toast.present();
                    }
                });
        });
    }
}

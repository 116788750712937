import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'abs-tooltip',
    template: `<ion-card-content [innerHTML]="description"> </ion-card-content>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent {
    @Input()
    description: string;
}

import { Injectable } from '@angular/core';
import { Logger } from '@ngrx/data';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { debounceTime, defaultIfEmpty, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';
import { UnitTemplateLibrary } from '../global/unit-library';
import { ForceUtils } from './force.utils';
import { Force, PointsAdjustment, Unit } from './models';
import { entityChangeDataLoaded, EntityChangeSelector, LocalStorageDataService, NativeStorageService } from '../global/data';
import { removeSharedForce } from './shared-forces.actions';
import { ForceSyncService } from '../global/sync/sync.force';
import { HttpClientWithInFlightCache } from '../global/httpClient';
import { ArmyBuilderConfig } from '../global/config';
import { SettingsService } from '../global/settings/service/settings.service';
import {
    addForce,
    addPointsAdjustment,
    deleteForce,
    loadAllForces,
    removePointsAdjustment,
    updateForce,
    upsertForce
} from './state/actions';
import { selectForces } from './state/selectors';
import { Store } from '@ngrx/store';
import { selectRouter } from '../global';

@Injectable({ providedIn: 'root' })
export class ForceDataService extends LocalStorageDataService<Force> {
    name = 'forces';
}

let entityChangeSub: Subscription;

@Injectable()
export class ForceService {
    ready$ = new BehaviorSubject(false);
    route$: Observable<any> = this.store.select(selectRouter).pipe(distinctUntilChanged());
    selectedPlatoonId$ = this.route$.pipe(
        map((r) => parseInt(r.state.queryParams.platoonId)),
        distinctUntilChanged(),
        shareReplay(1)
    );

    // getAll$ = of(0).pipe(
    //     map(() => this.name),
    //     switchMap((name) =>
    //         from(
    //             this.storage.getItem(name, []).then((entities: T[]) => {
    //                 this.entities = entities;
    //                 return entities;
    //             })
    //         )
    //     ),
    //     distinctUntilChanged(),
    //     shareReplay(1)
    // );

    postProcessCache: { [forceId: string]: Force } = {};

    forceId$: Observable<any> = this.route$.pipe(
        map((r) => r?.state?.params.forceId),
        distinctUntilChanged(),
        shareReplay(1)
    );

    forces$: Observable<Force[]> = this.ready$.pipe(
        filter((ready) => ready),
        switchMap(() => this.settingsService.login$),
        filter((l) => !!l),
        switchMap(() =>
            combineLatest([this.store.select(selectForces).pipe(distinctUntilChanged()), this.unitTemplateLibrary.units$, this.forceId$])
        ),
        map(([forces, unitTemplates, forceId]): Force[] =>
            forces
                .filter((f) => f.gameId === this.forceUtils.gameId)
                .map((force) => this.forceUtils.preProcessForce(force, unitTemplates, forceId))
        ),
        switchMap(
            (forces): Observable<Force[]> =>
                this.forceSyncService.entityChangeCache$.pipe(
                    // debounceTime(100),
                    switchMap((entityChangeCache) =>
                        forkJoin(
                            forces.map((f: any) => {
                                let state = entityChangeCache.find((x) => x.forceId === f.id)?.state;
                                if (state === 'Clean' && Object.keys(this.postProcessCache).includes(f.id)) {
                                    return of({
                                        ...this.postProcessCache[f.id],
                                        selected: f.selected
                                    });
                                }
                                return this.forceUtils.processForce(f).pipe(
                                    tap((processedForce) => {
                                        this.postProcessCache[f.id] = processedForce;
                                    }),
                                    take(1)
                                );
                            })
                        ).pipe(defaultIfEmpty([]))
                    )
                )
        ),
        switchMap(
            (forces): Observable<Force[]> => forkJoin(forces.map((f) => this.forceUtils.postProcessForce(f))).pipe(defaultIfEmpty([]))
        ),
        defaultIfEmpty([]),
        distinctUntilChanged(),
        shareReplay(1)
    ) as Observable<Force[]>;

    unitId$: Observable<any> = this.route$.pipe(
        map((r) => r.state.params.unitId),
        distinctUntilChanged()
    );

    shared$ = this.route$.pipe(
        map((r) => !!r.state?.data?.shared),
        distinctUntilChanged(),
        shareReplay(1)
    );

    force$: Observable<Force> = combineLatest([this.forceId$, this.shared$, this.unitTemplateLibrary.units$]).pipe(
        switchMap(([forceId, shared, unitTemplates]) => {
            if (shared) {
                return this.forceUtils.getForceFromServer(forceId).pipe(
                    map((f) => this.forceUtils.preProcessForce(f, unitTemplates, f.id)),
                    switchMap((f) => this.forceUtils.processForce(f)),
                    switchMap((f) => this.forceUtils.postProcessForce(f))
                );
            }

            return this.forces$.pipe(
                map((forces) => {
                    let force = forces.find((f) => f.id === forceId);
                    return force;
                })
            );
        }),
        filter((f) => !!f),
        distinctUntilChanged(),
        shareReplay(1)
    );

    units$: Observable<any> = this.force$.pipe(
        filter((f) => !!f),
        map((force) => force.units),
        distinctUntilChanged(),
        defaultIfEmpty([])
    );

    unit$: Observable<any> = combineLatest([this.force$, this.unitId$]).pipe(
        map((results) => {
            const force: Force = results[0];
            const unitId: string = results[1];

            return force.units.find((u) => u.id === unitId);
        }),
        filter((f) => !!f),
        distinctUntilChanged()
    );

    options$: Observable<any> = this.unit$.pipe(
        map((unit) => unit.options),
        distinctUntilChanged()
    );

    constructor(
        private unitTemplateLibrary: UnitTemplateLibrary,
        public forceUtils: ForceUtils,
        private forceSyncService: ForceSyncService,
        private httpClient: HttpClientWithInFlightCache,
        private config: ArmyBuilderConfig,
        private settingsService: SettingsService,
        private logger: Logger,
        private storage: NativeStorageService,
        protected store: Store
    ) {}

    copyForce(force: Force) {
        let newForce = {
            ...force,
            id: null,
            shareViaURL: false,
            sharedWith: [],
            name: force.name + ' Copy'
        };
        return this.add(newForce);
    }

    saveSharedForce(force: Force) {
        return this.copyForce({ ...force });
    }

    removeShare(forceId: string, gameId: string) {
        const url = `${this.config.apiBaseUrl}/userData/forces/shared/${gameId}/${forceId}`;
        return this.httpClient.delete(url).subscribe(() => {
            this.store.dispatch(removeSharedForce({ forceId, gameId }));
        });
    }

    protected initGame() {}
    init() {
        // N.B. this relies on the ForceService NOT being a singleton, as it allows each
        // module to load its own translations and THEN ready up the service, which
        // leads to each force being processed by that module's ForceUtils, where the
        // translations are needed.
        this.initGame();
        this.ready$.next(true);
        this.store
            .select(selectForces)
            .pipe(debounceTime(1000))
            .subscribe((forces) => {
                this.storage.setItem('forces', forces);
            });
    }

    add(force: Force) {
        this.logger.log('ForceService.add');
        let newId = force.id || ('' + Date.now() + Math.floor(Math.random() * 1000)).toString();

        force = { ...force, id: newId };

        this.store.dispatch(addForce({ force }));
        return of(force);
    }

    addUnit(newUnit: Unit, force: Force) {
        return this.update({
            id: force.id,
            units: [...force.units, newUnit]
        });
    }

    deleteUnit(force: Force, unit: Unit) {
        return this.update({
            id: force.id,
            units: force.units.filter((u) => u.id !== unit.id)
        });
    }

    async loadAll() {
        let entityChangeData = await this.storage.getItem('EntityChangeSelector', {});
        let deletedForceIds = Object.entries(entityChangeData?.forces || {})
            .filter((x) => x[1] === 'Deleted')
            .map((x) => x[0]);

        this.store.dispatch(entityChangeDataLoaded(entityChangeData));

        if (!entityChangeSub) {
            console.log('Setting up entityChangeSub');
            entityChangeSub = this.store
                .select(EntityChangeSelector)
                .pipe(debounceTime(500))
                .subscribe((state) => {
                    console.log('EntityChangeSelector', state);
                    this.storage.setItem('EntityChangeSelector', state);
                });
        }

        let entities = await this.storage.getItem('forces', []);
        this.store.dispatch(loadAllForces({ forces: entities.filter((f) => !deletedForceIds.includes(f.id)) }));
    }

    updateUnit(force: Force, unit: Unit) {
        this.logger.log('ForceService.updateUnit');

        let updatedForce: Force = {
            ...force,
            units: force.units.map((u) => {
                if (u.id === unit.id) {
                    return unit;
                }
                return u;
            })
        };

        return this.update(updatedForce);
    }

    update(force: Partial<Force> & { id: string }) {
        this.logger.log('ForceService.update');

        this.store.dispatch(updateForce({ force }));
        return of(force);
    }

    upsert(force: Force) {
        this.logger.log('ForceService.upsert');
        if (!force.id) {
            let newId = force.id || ('' + Date.now() + Math.floor(Math.random() * 1000)).toString();
            force = {
                ...force,
                id: newId
            };
        }
        this.store.dispatch(upsertForce({ force }));

        return of(force);
    }

    delete(forceId: string) {
        this.logger.log('ForceService.delete');
        let deletedForceId$ = this.forces$.pipe(
            take(1),
            map((forces) => {
                let force = forces.find((f) => f.id === forceId);
                let updatedAt = force.updatedAt;

                this.store.dispatch(deleteForce({ id: forceId, updatedAt }));

                return forceId;
            })
        );

        deletedForceId$.subscribe((forceId) => {
            console.log('Force deleted: ' + forceId);
        });

        return deletedForceId$;
    }

    addPointsAdjustmentToUnit(forceId: string, unitId: string, pointsAdjustment: PointsAdjustment) {
        this.store.dispatch(addPointsAdjustment({ forceId, unitId, pointsAdjustment }));
    }

    removePointsAdjustmentFromUnit(forceId: string, unitId: string, index: number) {
        this.store.dispatch(removePointsAdjustment({ forceId, unitId, index }));
    }
}

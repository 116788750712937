import { createAction, props } from '@ngrx/store';
import { Force, PointsAdjustment } from '../models';

export const loadAllForces = createAction('[Force] loadAllForces', props<{ forces: Force[] }>());
export const addForce = createAction('[Force] addForce', props<{ force: Force }>());
export const updateForce = createAction('[Force] updateForce', props<{ force: Partial<Force> & { id: string } }>());
export const upsertForce = createAction('[Force] upsertForce', props<{ force: Force }>());
export const deleteForce = createAction('[Force] deleteForce', props<{ id: string; updatedAt: number }>());
export const addPointsAdjustment = createAction(
    '[Force] addPointsAdjustment',
    props<{ forceId: string; unitId?: string; pointsAdjustment: PointsAdjustment }>()
);
export const removePointsAdjustment = createAction(
    '[Force] removePointsAdjustment',
    props<{ forceId: string; unitId?: string; index: number }>()
);

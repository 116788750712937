import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, distinctUntilChanged, take } from 'rxjs/operators';

// Convenient way to execute code on the last value emitted by an observable.
export const snapshot = <T extends unknown>(observable: Observable<T>, callback: (val: T) => void) => {
    observable
        .pipe(
            filter((x) => x !== undefined),
            distinctUntilChanged(),
            take(1)
        )
        .subscribe((val) => {
            callback(val);
        });
};

export const dateString = (date: Date): string => {
    let y = date.getFullYear();
    let m = (date.getMonth() + 1).toString().padStart(2, '0');
    let d = date.getDate().toString().padStart(2, '0');
    let hr = date.getHours().toString().padStart(2, '0');
    let min = date.getMinutes().toString().padStart(2, '0');
    let sec = date.getSeconds().toString().padStart(2, '0');
    let ms = date.getMilliseconds().toString().padStart(3, '0');
    return `${y}-${m}-${d} ${hr}:${min}:${sec}.${ms}`;
};

export const retry = (intervals: number[], callback: (resolve, reject, i?: number) => void, runEveryIteration = false) => {
    let resolved = false;
    // intervals = [500];
    // const setTimeout = window['__zone_symbol__setTimeout'];
    return new Promise<any>((resolve, reject) => {
        intervals.forEach((i) => {
            setTimeout(() => {
                if (resolved && !runEveryIteration) {
                    return;
                }
                callback(
                    (value) => {
                        resolved = true;
                        resolve(value);
                    },
                    (reason: any) => {
                        if (i === intervals[intervals.length - 1]) {
                            let msg = `${reason} after ${i}ms`;
                            reject(msg);
                        }
                    },
                    i
                );
            }, i);
        });
    });
};

export const getUniqueId = () => Math.ceil(Math.random() * 100000);
export const sum = (agg, x) => agg + x;
export const hasValue = (x: any): boolean => x !== null && x !== undefined;

export const markFormTouched = (group: UntypedFormGroup | UntypedFormArray) => {
    Object.keys(group.controls).forEach((key: string) => {
        const control = group.controls[key];
        if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
            control.markAsTouched();
            markFormTouched(control);
        } else {
            control.markAsTouched();
        }
    });
};

export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const isFalsyNonZero = (val: any) => {
    return (val as any) === '' || val === null || val === undefined;
};

export const extractContentFromHTML = (html: string) => {
    var span = document.createElement('span');
    span.innerHTML = html;
    return span.textContent || span.innerText;
};

export interface DiffResult {
    equal: boolean;
    changedFields?: { [fieldKey: string]: { old: any; new: any } };
}

const isObjectBasicallyNull = (obj) => {
    if (obj === null || obj === undefined) {
        return true;
    }
    const keys = Object.keys(obj);
    for (let key of keys) {
        if (obj[key]) {
            if (typeof obj[key] === 'object') {
                return isObjectBasicallyNull(obj[key]);
            }
            return false;
        }
    }
    return true;
};

export const formDiff = (object1, object2): DiffResult => {
    const objKeys1 = Object.keys(object1);
    const objKeys2 = Object.keys(object2);
    let changedFields = {};
    let diffFields = [];

    if (isObjectBasicallyNull(object1) && isObjectBasicallyNull(object2)) {
        return {
            equal: true,
            changedFields: {}
        };
    }

    if (objKeys1.length !== objKeys2.length) {
        let k1 = objKeys1.filter((x) => !objKeys2.includes(x));
        let k2 = objKeys2.filter((x) => !objKeys1.includes(x));
        diffFields.push(...k1);
        diffFields.push(...k2);
    }

    for (var key of objKeys1) {
        const value1 = object1[key];
        const value2 = object2[key];

        const isObjects = isObject(value1) && isObject(value2);

        if (isObjects) {
            let diffResult = formDiff(value1, value2);
            if (!diffResult.equal) {
                for (let [childKey, val] of Object.entries(diffResult.changedFields)) {
                    changedFields[`${key}.${childKey}`] = val;
                }
            }
        } else if (!isObjects && value1 !== value2) {
            let equivalentNull = isObjectBasicallyNull(value1) && isObjectBasicallyNull(value2);
            if (!equivalentNull) {
                changedFields[key] = {
                    old: value1,
                    new: value2
                };
            }
        }
    }
    return {
        equal: Object.keys(changedFields).length === 0,
        changedFields
    };
};

const isObject = (object) => {
    return object != null && typeof object === 'object';
};

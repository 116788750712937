import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';
import { WarlordUserService } from './user.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { UntypedFormBuilder } from '@angular/forms';
import { snapshot } from 'army-builder-shared';
import { DevicePlatform, SocialSigninService } from './service/social-signin.service';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { getRedirectResult } from '@angular/fire/auth';

@Component({
    selector: 'wl-login',
    template: `
        <div
            *ngLet="{
                loginState: userService.login$ | async
            } as props"
        >
            <div class="error" *ngIf="props.loginState.error">
                {{ 'GLOBAL.ERRORS.' + props.loginState.error | translate }}
            </div>

            <div class="loggedIn" *ngIf="props.loginState.user; else loggedOut">
                <div class="buttons" *ngIf="showLogout">
                    <ion-button (click)="logout()">
                        {{ 'GLOBAL.LOGIN.BUTTONS.LOGOUT' | translate }}
                        <ion-spinner name="crescent" *ngIf="props.loginState.loading"></ion-spinner>
                    </ion-button>
                </div>
            </div>
            <ng-template #loggedOut>
                <div class="loginButtons">
                    <ion-button type="submit" *ngIf="platform.is('ios')" class="apple" (click)="appleLogin()" expand="block">
                        <ion-icon name="logo-apple"></ion-icon>
                        Login with Apple <ion-spinner name="crescent" *ngIf="props.loginState.loading"></ion-spinner>
                    </ion-button>
                    <ion-button type="submit" class="google" color="danger" (click)="googleLogin()" expand="block">
                        <ion-icon name="logo-google"></ion-icon>
                        Login with Google <ion-spinner name="crescent" *ngIf="props.loginState.loading"></ion-spinner>
                    </ion-button>
                    <ion-button type="submit" *ngIf="!platform.is('ios')" class="apple" (click)="appleLogin()" expand="block">
                        <ion-icon name="logo-apple"></ion-icon>
                        Login with Apple <ion-spinner name="crescent" *ngIf="props.loginState.loading"></ion-spinner>
                    </ion-button>
                    <!-- <ion-button type="submit" class="facebook" (click)="facebookLogin()" expand="block">
                        <ion-icon name="logo-facebook"></ion-icon>
                        Login with Facebook <ion-spinner name="crescent" *ngIf="props.loginState.loading"></ion-spinner>
                    </ion-button> -->
                </div>
            </ng-template>
        </div>
    `,
    styles: [
        `
            .error {
                margin: 10px 0;
            }

            .buttons {
                padding: 0;
            }

            .loginButtons ion-icon {
                margin-right: 10px;
            }

            .loggedOut {
                padding: 0 2px 5px;
            }

            ion-input {
                border: none;
            }

            .google {
                --ion-color-primary: #de5246;
                color: #fff;
            }

            .apple {
                --ion-color-primary: #000;
                color: #fff;
            }

            .facebook {
                --ion-color-primary: #4267b2;
                color: #fff;
            }
        `
    ]
})
export class LoginComponent {
    @Input()
    infoPopup: boolean;

    @Input()
    showInfo: boolean = true;

    @Input()
    showLogout: boolean = true;

    @Input()
    showLogin: boolean = true;

    @Input()
    showRegister: boolean = true;

    @Input()
    showGuest: boolean = false;

    formGroup = this.fb.group({
        username: this.fb.control(''),
        password: this.fb.control('')
    });

    constructor(
        public userService: WarlordUserService,
        private iab: InAppBrowser,
        public popoverController: PopoverController,
        private fb: UntypedFormBuilder,
        private socialSigninService: SocialSigninService,
        public platform: Platform
    ) {}

    async ngAfterViewInit() {
        //     let showPromptTimeout;
        //     this.userService.login$.pipe(filter((l) => !l.loading)).subscribe((l) => {
        //         if (!l.user) {
        //             if ((window as any).google) {
        //                 (window as any).google.accounts.id.initialize({
        //                     client_id: '389074739419-ebtu14i5vr8icv94uf5543fndbmd0oqf.apps.googleusercontent.com',
        //                     callback: (x) => this.handleCredentialResponse(x)
        //                 });
        //                 (window as any).google.accounts.id.renderButton(
        //                     this.loginBtn.nativeElement,
        //                     { theme: 'outline', size: 'large' } // customization attributes
        //                 );
        //                 console.log('setting timeout for one tap login');
        //                 showPromptTimeout = setTimeout(() => {
        //                     console.log('showing one tap login');
        //                     (window as any).google.accounts.id.prompt(); // also display the One Tap dialog
        //                 }, 500);
        //             }
        //         } else {
        //             if (showPromptTimeout) {
        //                 clearTimeout(showPromptTimeout);
        //             }
        //         }
        //     });
    }

    async showInfoPopup(ev: any) {
        const popover = await this.popoverController.create({
            component: LoginInfoComponent,
            event: ev,
            translucent: true
        });
        return await popover.present();
    }

    login() {
        let { username, password } = this.formGroup.value;
        this.userService.login({ username, password });
    }

    logout() {
        this.userService.logout();
    }

    register() {
        this.iab.create('https://www.warlordgames.com/register/');
    }

    deleteAccount() {
        snapshot(this.userService.login$, (login) => {
            let username = login?.user?.username;
            if (username) {
                this.iab.create(`https://www.warlordgames.com/community-members/${username}/settings/delete-account/`);
            } else {
                console.error('Error loading user data', { login });
            }
        });
    }

    manageAccount() {
        snapshot(this.userService.login$, (login) => {
            let username = login?.user?.username;
            if (username) {
                this.iab.create(`https://www.warlordgames.com/community-members/${username}/profile/edit/group/1/`);
            } else {
                console.error('Error loading user data', { login });
            }
        });
    }

    async googleLogin() {
        let platform: DevicePlatform = this.getPlatform();
        await this.socialSigninService.googleLogin(platform);
    }

    private getPlatform() {
        let platform: DevicePlatform;
        if (this.platform.is('android')) {
            platform = 'android';
        } else if (this.platform.is('ios')) {
            platform = 'ios';
        } else {
            platform = 'web';
        }
        return platform;
    }

    async appleLogin() {
        let platform: DevicePlatform = this.getPlatform();
        await this.socialSigninService.appleLogin(platform);
    }

    async facebookLogin() {
        let platform: DevicePlatform = this.getPlatform();
        await this.socialSigninService.facebookLogin(platform);
    }
}

@Component({
    selector: 'wl-login-info',
    template: `<p>{{ 'GLOBAL.LOGIN.DESCRIPTION' | translate }}</p>`
})
export class LoginInfoComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}

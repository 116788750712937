import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule, Platform } from '@ionic/angular';
import { ListModule } from '../global/components/list.module';
import { PageLayoutModule } from '../global/page-layout';
import {
    AddUnitPage,
    ForcePage,
    ForceListPage,
    ForceTypePage,
    UnitPage,
    UnitOptionsPage,
    UnitDetailsPage,
    UnitDetailsComponent,
    ForceValidationComponent,
    PDFSettingsComponent,
    ShareForceComponent,
    UnitPoinstAdjustmentsComponent
} from './components';
import { RouterModule } from '@angular/router';
import { ForceService } from './force.service';
import { NgLetModule } from 'ng-let';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageModule } from '../global/language/language.module';
import { FORCE_PIPES } from './pipes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PDFService } from './components/pdf-settings/pdf-service';
import { SharingModule } from '../global/sharing';
import { QRCodeModule } from '../global/qr-code-service';
import { StoreModule } from '@ngrx/store';
import { sharedForcesReducer } from './shared-forces.reducer';
import { ImportForceComponent } from './components/import-force/import-force.component';
import { PlatoonSelectComponent } from './components/platoon-select/platoon-select.component';
import { getCombinedForceReducers } from './state/reducer';
import { EffectsModule } from '@ngrx/effects';
import { ForceEffects } from './state/effects';

let forcesLoaded = false;

export const FORCE_COMPONENTS = [
    AddUnitPage,
    ForceListPage,
    ForcePage,
    ForceTypePage,
    UnitPage,
    UnitOptionsPage,
    UnitDetailsPage,
    UnitDetailsComponent,
    ForceValidationComponent,
    PDFSettingsComponent,
    ShareForceComponent,
    ImportForceComponent,
    PlatoonSelectComponent,
    UnitPoinstAdjustmentsComponent
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([]),
        IonicModule,
        ListModule,
        PageLayoutModule,
        TranslateModule,
        NgLetModule,
        LanguageModule,
        FormsModule,
        EffectsModule.forFeature([ForceEffects]),
        StoreModule.forFeature('forces', getCombinedForceReducers()),
        StoreModule.forFeature('sharedForces', sharedForcesReducer),
        SharingModule,
        QRCodeModule,
        ReactiveFormsModule
    ],
    declarations: [...FORCE_COMPONENTS, ...FORCE_PIPES],
    exports: [...FORCE_COMPONENTS, ...FORCE_PIPES],
    providers: [PDFService]
})
export class ArmyBuilderForceModule {
    constructor(forceService: ForceService, platform: Platform) {
        // entityDataService.registerService('Force', forceDataService);

        platform.ready().then(() => {
            if (!forcesLoaded) {
                // Hacky way to ensure forces only get loaded once
                // since this module is being lazy loaded, and thus
                // isn't a singleton
                forcesLoaded = true;
                forceService.loadAll();
            }
        });
    }
}

import { Component, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { snapshot } from '../../../global/utils';
import { AlertService } from '../../../global/alert/alert';
import { ArmyBuilderConfig } from '../../../global/config';
import { InputAlert } from '../../../global/input-alert/input-alert';
import { UnitOptionLibrary } from '../../../global/unit-option-library';
import { ForceService } from '../../force.service';
import { Force, UnitOption, Unit } from '../../models';
import { ForceUtils } from '../../force.utils';

@Component({
    selector: 'abs-unit-options',
    template: `
        <abs-page-layout
            *ngLet="{ unit: unit$ | async, force: force$ | async } as props"
            [title]="props.unit | unitName | language | async"
            [subTitle]="(props.unit | unitCost : props.force) + ('GLOBAL.PTS' | translate)"
        >
            <abs-list
                [items]="UnitOptionLibrary$ | async"
                (itemSelect)="itemSelected($event.item)"
                [groupBy]="'1'"
                [activeStyleField]="'quantity'"
                [template]="upgradeTemplate"
            ></abs-list>
        </abs-page-layout>

        <ng-template #upgradeTemplate let-item="item">
            <div class="itemWrap">
                <div class="itemName">
                    <strong>{{ item.name }}</strong>
                </div>
                <div class="itemCost">{{ item.cost }}{{ 'GLOBAL.PTS' | translate }}</div>
                <div class="itemDescription">{{ item.description }}</div>
            </div>
        </ng-template>
    `
})
export class UnitOptionsPage implements OnDestroy {
    unit$ = this.forceService.unit$;
    options$ = this.forceService.options$;
    force$ = this.forceService.force$;

    UnitOptionLibrary$ = combineLatest([this.options$, this.UnitOptionLibrary.getAll()]).pipe(
        map((results) => {
            const equippedUpgrades: UnitOption[] = results[0];
            const equippedUpgradeIds: number[] = equippedUpgrades.map((u) => u.id);
            const libraryUpgrades: UnitOption[] = results[1];
            return libraryUpgrades.map((u) => ({
                ...u,
                quantity: (equippedUpgrades[equippedUpgradeIds.indexOf(u.id)] || { quantity: 0 }).quantity
            }));
        })
    );

    unit: Unit;
    private unitSub = this.unit$.subscribe((u) => {
        if (!u) {
            setTimeout(() => this.zone.run(() => this.router.navigateByUrl('/forces'), 0));
        }
        this.unit = u;
    });

    force: Force;
    private forceSub = this.force$.subscribe((f) => {
        if (!f) {
            setTimeout(() => this.zone.run(() => this.router.navigateByUrl('/forces'), 0));
        }
        this.force = f;
    });

    constructor(
        public forceService: ForceService,
        public UnitOptionLibrary: UnitOptionLibrary,
        public router: Router,
        public config: ArmyBuilderConfig,
        public alertService: AlertService,
        public inputAlert: InputAlert,
        public zone: NgZone,
        public translateService: TranslateService,
        public unitOptionLibrary: UnitOptionLibrary,
        public forceUtils: ForceUtils
    ) {}

    itemSelected(upgrade: UnitOption) {
        snapshot(
            combineLatest([
                this.translateService.get('FORCES.DIALOGS.MAX_UPGRADES.TITLE'),
                this.translateService.get('FORCES.DIALOGS.MAX_UPGRADES.MESSAGE')
            ]),
            ([title, message]) => {
                const existingUpgrade = this.unit.selectedOptions.find((u) => u === upgrade.id);
                if (!existingUpgrade && this.unit.selectedOptions.length >= (this.unit as Unit).unitTemplate.maxUpgrades) {
                    this.alertService.showAlert(title, message);
                } else {
                    this.toggleUpgrade(upgrade);
                }
            }
        );
    }

    toggleUpgrade(upgrade: UnitOption) {
        const updatedForce = {
            id: this.force.id,
            units: [
                ...this.force.units.map((u) => ({
                    ...u,
                    selectedOptions: [...u.selectedOptions]
                }))
            ]
        };

        const unitToUpdate = updatedForce.units.find((x) => x.id === this.unit.id);
        const existingUpgrade = unitToUpdate.selectedOptions.find((u) => u === upgrade.id);

        if (existingUpgrade) {
            unitToUpdate.selectedOptions = unitToUpdate.selectedOptions.filter((u) => u !== upgrade.id);
        } else {
            const res = this.forceUtils.canUnitReceiveUpgrade(unitToUpdate, this.force, upgrade);
            if (res.canReceiveUpgrade) {
                unitToUpdate.selectedOptions.push(upgrade.id);
            } else {
                snapshot(
                    combineLatest([
                        this.translateService.get(res.errorMessage.headerKey),
                        this.translateService.get(res.errorMessage.messageKey)
                    ]),
                    ([header, message]) => {
                        this.alertService.showAlert(header, message);
                    }
                );
            }
        }
        this.forceService.update(updatedForce);
    }

    renameUnit() {
        // this.inputAlert.show(this.config.renameForceDialog.title, this.config.renameForceDialog.message, unit.name, true).then(name => {
        //   if (!name) {
        //     return;
        //   }
        //   this.forceService.update({
        //     id: unit.id,
        //     name
        //   });
        // });
    }

    deleteUpgrade() {
        // this.forceService.update({
        //   id: this.force.id,
        //   units: this.force.units.map(unit => ({
        //     ...unit,
        //     upgrades: unit.upgrades.filter(u => unit.id !== this.unit.id || u.key !== upgrade.key)
        //   }))
        // });
    }

    addUpgrade(e) {
        // this.forceService.update({
        //   id: this.force.id,
        //   units: this.force.units.map(unit => ({
        //     ...unit,
        //     upgrades: unit.upgrades.filter(u => unit.id !== this.unit.id || u.key !== upgrade.key)
        //   }))
        // });
    }

    ngOnDestroy() {
        this.forceSub.unsubscribe();
        this.unitSub.unsubscribe();
    }
}

import { Component } from '@angular/core';
import { SettingsService } from 'army-builder-shared';

@Component({
    selector: 'bs-settings',
    template: `
        <abs-page-layout
            title="Settings"
            *ngLet="{
                loginState: settingsService.login$ | async,
                settings: this.settingsService.settings$ | async
            } as props"
        >
            <div class="content">
                <div *ngIf="props.loginState.user" class="account">
                    <!-- <p class="content">
                    {{ 'GLOBAL.LOGIN.LOGGED_IN_MESSAGE' | translate: props.loginState.user }}
                </p>
                <div class="buttons">
                    <ion-button [routerLink]="['user']">{{ 'GLOBAL.MENU_ITEMS.USER' | translate }}</ion-button>
                </div> -->

                    <wl-user></wl-user>
                    <!-- <wl-manage-subscription></wl-manage-subscription> -->
                </div>
                <abs-devices></abs-devices>
                <abs-settings></abs-settings>

                <!-- <div class="buttons">
                <ion-button [routerLink]="['debug']">{{ 'GLOBAL.SETTINGS.DEBUG.TITLE' | translate }}</ion-button>
            </div> -->
            </div>
        </abs-page-layout>
    `,
    styles: [
        `
            .account .content {
                margin-bottom: 0;
            }
        `
    ]
})
export class SettingsPage {
    constructor(public settingsService: SettingsService) {}
}

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Translation } from '../i18n/translation.model';

const DEFAULT_LANG = 'en';
export let LANGUAGE = DEFAULT_LANG;
export const selectedLanguage$ = of(LANGUAGE);
selectedLanguage$.subscribe((l) => (LANGUAGE = l));
export const getValueFromTranslation = (translatable: Translation): Observable<string> => {
    return selectedLanguage$.pipe(map((lang) => getValueFromTranslationSync(translatable, lang)));
};

export const getValueFromTranslationSync = (translatable: Translation, lang?: string): string => {
    if (!translatable) {
        return '';
    }

    if (typeof translatable === 'string') {
        return translatable;
    }

    let value = translatable[lang || LANGUAGE] || translatable[DEFAULT_LANG];
    if (value !== undefined) {
        return value;
    }

    if (!translatable.key) {
        // console.error('Error translating value', translatable);
        return '';
    }
};

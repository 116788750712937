import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { BaseFormControl } from '../base-control';
import { FormTagsField, FormTranslatableTagsField } from '../form-field';
import { createTagsFormGroup } from './functions';
import { Pipe, PipeTransform } from '@angular/core';

@Component({
    selector: 'abs-tags',
    template: `
        <div class="form-group" *ngIf="parentFormGroup" [formGroup]="parentFormGroup">
            <label *ngIf="!field.hideLabel">{{ field.label || (field.name | formLabel) }} </label>
            <div [formGroupName]="field.name" class="tags">
                <span *ngFor="let fc of formArray?.controls; let i = index" (auxclick)="$event.which === 2 ? removeItem($event, i) : null">
                    {{ fc | tagLabel : field.options }}
                    <span class="removeTag" (click)="removeItem($event, i)">x</span>
                    <input type="hidden" [formControlName]="i" />
                </span>

                <input
                    type="text"
                    [value]="value"
                    (input)="updateValue($event)"
                    (keydown.Enter)="onEnter($event)"
                    absAutoComplete
                    [options]="field.options"
                    [mapOptionsFn]="field.mapItems"
                    [sortOptionsFn]="field.sortOptionsFn ? field.sortOptionsFn(formValue) : undefined"
                    (selectionUpdate)="selectionUpdate($event)"
                    (commitSelection)="commitSelection($event)"
                />
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsComponent extends BaseFormControl {
    @Input()
    field: FormTagsField | FormTranslatableTagsField;

    value: string = '';

    options$ = new BehaviorSubject([]);

    get formArray() {
        return this.parentFormGroup.get(this.field.name) as UntypedFormArray;
    }

    getNewValue(value: string): any {
        // Done so TranslatableTagsComponent can override this method
        return value;
    }

    getFormGroup(): any {
        // Done so TranslatableTagsComponent can override this method
        return createTagsFormGroup();
    }

    onEnter(e) {
        let val = e.srcElement.value.trim();
        if (!val || this.formArray.value.includes(val)) {
            return;
        }
        this.addItem(val);
        e.target.focus();
    }

    addItem(val) {
        let newValue = [...this.formArray.value, this.getNewValue(val)];
        this.formArray.push(this.getFormGroup());
        this.formArray.patchValue(newValue);
        this.value = '';
    }

    removeItem(e: any, index: number) {
        e.preventDefault();
        e.stopPropagation();
        this.formArray.removeAt(index);
    }

    updateValue(e) {
        this.value = e.target.value || '';
    }

    selectionUpdate(e) {
        this.value = e || '';
    }

    commitSelection(val) {
        this.addItem(val);
    }
}

@Pipe({
    name: 'tagLabel'
})
export class TagLabelPipe implements PipeTransform {
    transform(fc: any, options: any[]): any {
        // the id field is used by the complex tags component only
        let val = fc.value?.id || fc.value?.label || fc.value?.value || fc.value || fc;
        return options.find((o) => o.value === val)?.label || val;
    }
}

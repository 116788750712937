import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { ArmyBuilderConfig } from '../config';
import { entitySyncComplete, entitySyncError, SelectSyncStatus, startEntitySync } from '../data';
import { HttpClientWithInFlightCache } from '../httpClient';

@Injectable({ providedIn: 'root' })
export abstract class BaseSyncService {
    abstract entityType: string;

    constructor(
        protected httpClient: HttpClientWithInFlightCache,
        protected store: Store<any>,
        protected modalController: ModalController,
        protected config: ArmyBuilderConfig
    ) {
        this.init();
    }

    abstract init();
    abstract sync();

    protected start(entityType: string, entityId?: string) {
        this.store.dispatch(startEntitySync({ entityType, entityId }));
    }

    protected error(entityType: string, entityId?: string) {
        setTimeout(() => {
            this.store.dispatch(entitySyncError({ entityType, entityId }));
        }, 500);
    }

    protected complete(entityType: string, entityId?: string) {
        setTimeout(() => {
            this.store.dispatch(entitySyncComplete({ entityType, entityId }));
        }, 500);
    }

    protected getSyncUrl(id?: string) {
        return `${this.config.apiBaseUrl}/userData/${this.entityType}/${id || ''}`;
    }

    protected getServerTimestamp(entityId?: any): Observable<number> {
        let headers = this.config.globalRequestHeaders;
        return this.httpClient.get(`${this.getSyncUrl(entityId)}/timestamp`, { headers });
    }

    protected getStatus(entityType: string, entityId?: any) {
        return this.store.select(SelectSyncStatus, { entityType: entityType, id: entityId });
    }

    protected handleSyncError(entityId?: string) {
        return catchError((err) => {
            this.error(this.entityType, entityId);
            throw err;
        });
    }
}

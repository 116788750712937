import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import { DATA_ACTIONS, entityChangeTimeoutCheck, entityChangeDataLoaded } from './actions';
import { NativeStorageService } from './native-storage.service';
import { EntityChangeState } from './reducer';

@Injectable()
export class EntityChangeEffects {
    loadEntityChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DATA_ACTIONS.loadEntityChangeDataFromCache),
            mergeMap(() => from(this.storage.getItem('entityChange', {}))),
            map((entityChangeData: EntityChangeState) => entityChangeDataLoaded(entityChangeData))
        )
    );

    startSync$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DATA_ACTIONS.startEntitySync),
            debounceTime(10000),
            mergeMap((entityChangeData: any) => of(entityChangeTimeoutCheck(entityChangeData)))
        )
    );

    constructor(private actions$: Actions, private storage: NativeStorageService, private store: Store<any>) {
        this.actions$
            .pipe(
                ofType(DATA_ACTIONS.markEntityAsDirty, DATA_ACTIONS.entitySyncComplete),
                mergeMap(() => this.store.select('entityChange')),
                debounceTime(10000)
            )
            .subscribe((changeData) => {
                console.log({ changeData });
                // (changeData) => this.storage.setItem('entityChange', changeData)
            });
    }
}

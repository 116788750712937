import { Component, OnInit, Input } from '@angular/core';
import { Unit } from '../../../models';

@Component({
    selector: 'abs-unit-details',
    template: ` {{ unit | unitName | async }} `
})
export class UnitDetailsComponent {
    @Input() unit: Unit;

    constructor() {}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgLetModule } from 'ng-let';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientWithInFlightCacheModule, ModalLayoutModule, PipesAndDirectivesModule, PurchaseModule } from 'army-builder-shared';
import { PageLayoutModule } from '../global/page-layout';

import { CancelSubscriptionComponent } from './cancel-subscription.component';
import { ManageSubscriptionComponent } from './manage-subscription.component';
import { SubscribeComponent } from './subscribe.component';
import { SubscriptionComponent } from './subscription.component';
import { SUBSCRIPTION_ROUTES } from './subscription.routes';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        PageLayoutModule,
        TranslateModule,
        RouterModule.forChild(SUBSCRIPTION_ROUTES),
        PurchaseModule,
        NgLetModule,
        PipesAndDirectivesModule,
        ModalLayoutModule,
        HttpClientWithInFlightCacheModule
    ],
    exports: [ManageSubscriptionComponent, SubscribeComponent],
    declarations: [SubscriptionComponent, ManageSubscriptionComponent, SubscribeComponent, CancelSubscriptionComponent],
    providers: []
})
export class SubscriptionModule {}

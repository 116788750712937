import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageModule } from '../language';
import { ListComponent } from './list';
import { DynamicListComponent } from './list.dynamic';

@NgModule({
    declarations: [ListComponent, DynamicListComponent],
    imports: [CommonModule, IonicModule, RouterModule.forChild([]), TranslateModule, LanguageModule],
    exports: [ListComponent, DynamicListComponent]
})
export class ListModule {}

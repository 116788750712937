import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { markFormTouched } from '../global/utils';
import { SupportService } from './support.service';
import { Store } from '@ngrx/store';
import { updateIssue } from './support.actions';
import { selectIssueById } from './support.selectors';
import { Subscription, Observable, map, take, combineLatest } from 'rxjs';
import { SupportIssue, SupportMode } from './model';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ArmyBuilderConfig, HttpClientWithInFlightCache, UserService } from '../global';
dayjs.extend(relativeTime);

@Component({
    selector: 'abs-issue-detail',
    template: `<abs-modal-layout>
        <!-- (close)="this.onDismiss(this.issue)" -->
        <ng-container *ngIf="issue$ | async as issue">
            <p><a href="#" (click)="toggleRaw($event)">Toggle Raw Data</a></p>
            <ng-container *ngIf="!showRaw">
                <p>
                    Status: <abs-issue-status [status]="issue.status"></abs-issue-status>
                    <strong>{{ issue.status }}</strong>
                </p>
                <p>
                    Page: <a [href]="issue.url" target="_blank">{{ issue.url }}</a>
                </p>
                <p>
                    Reported <strong><abs-relative-date [date]="issue.createdAt"></abs-relative-date></strong> by
                    <strong>{{ issue.userData.user.name }}</strong>
                </p>
                <pre>{{ issue.description }}</pre>
            </ng-container>
            <pre *ngIf="showRaw">{{ issue | json }}</pre>
            <form *ngIf="issueForm" [formGroup]="issueForm" (ngSubmit)="onSubmit()">
                <div *ngIf="mode !== 'user'">
                    <ion-label>Status:</ion-label>
                    <ion-select formControlName="issueStatus">
                        <ion-select-option [value]="'New'">New</ion-select-option>
                        <ion-select-option [value]="'In progress'">In progress</ion-select-option>
                        <ion-select-option [value]="'Resolved'">Resolved</ion-select-option>
                        <ion-select-option [value]="'Rejected'">Rejected</ion-select-option>
                        <ion-select-option [value]="'Waiting for customer'">Waiting for customer</ion-select-option>
                        <ion-select-option [value]="'Customer responded'">Customer responded</ion-select-option>
                    </ion-select>
                </div>
                <div>
                    <ion-label>Comment:</ion-label>
                    <ion-textarea formControlName="comment"></ion-textarea>
                </div>
                <ion-button [disabled]="!issueForm.valid" type="submit">{{ mode === 'user' ? 'Comment' : 'Update Issue' }}</ion-button>
            </form>

            <ng-container *ngIf="!showRaw">
                <h2>Updates:</h2>
                <ng-template #updateTemplate let-update="item">
                    <div class="issueUpdate">
                        <p>
                            Updated
                            <strong><abs-relative-date [date]="update.updateDate"></abs-relative-date></strong> by
                            <strong>{{ update.updatedBy }}</strong>
                        </p>
                        <p *ngIf="update.issueStatus">New status: {{ update.issueStatus }}</p>
                        <pre>{{ update.description }}</pre>
                    </div>
                </ng-template>
                <abs-list [items]="issue.updates" [template]="updateTemplate"></abs-list>
            </ng-container>
        </ng-container>
    </abs-modal-layout>`,
    styles: [
        `
            pre {
                max-height: 300px;
                overflow: auto;
                border: 1px solid #e0e0e0;
                padding: 10px;
                width: 100%;
                white-space: break-spaces;
            }

            .issueUpdate {
                width: 100%;
                padding: 12px 0;
            }

            .issueUpdate p,
            .issueUpdate pre {
                margin: 0;
            }
        `
    ]
})
export class IssueDetailComponent implements OnInit {
    @Input()
    issueId: string = null;

    issue$: Observable<SupportIssue>;

    @Input()
    onDismiss: (res: any) => void = (_) => {};

    @Input()
    mode: SupportMode = 'admin';

    issueFormSub: Subscription;
    issueForm: UntypedFormGroup;
    showRaw = false;

    constructor(
        private config: ArmyBuilderConfig,
        private fb: UntypedFormBuilder,
        private supportService: SupportService,
        private store: Store,
        private httpClient: HttpClientWithInFlightCache,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.issue$ = this.store
            .select((state) => selectIssueById(state, this.issueId))
            .pipe(
                map((issue) => ({
                    ...issue,
                    updates: [...issue.updates].sort((a, b) => new Date(b.updateDate).getTime() - new Date(a.updateDate).getTime())
                }))
            );

        combineLatest([this.userService.login$, this.issue$])
            .pipe(take(1))
            .subscribe(([l, issue]) => {
                if (l.user?.email === issue.reporterEmail) {
                    this.supportService.markIssueRead(issue._id);
                }
            });

        this.issueFormSub = this.issue$.subscribe((issue) => {
            if (!issue) {
                return;
            }

            this.issueForm = this.fb.group({
                comment: [null, Validators.required],
                issueStatus: [issue.status, Validators.required]
            });
        });
    }

    onSubmit() {
        markFormTouched(this.issueForm);

        if (this.issueForm.valid) {
            this.supportService.updateIssue(this.issueId, this.issueForm.getRawValue(), this.mode).subscribe((res) => {
                // this.issue = { ...res, updates: res.updates.sort((a, b) => b.date - a.date) };

                this.store.dispatch(updateIssue(res));
                this.issueForm.reset();
            });
        }
    }

    toggleRaw(e) {
        e.preventDefault();
        this.showRaw = !this.showRaw;
    }

    ngOnDestroy() {
        if (this.issueFormSub) {
            this.issueFormSub.unsubscribe();
        }
    }
}

import { NgModule } from '@angular/core';
import { SettingsComponent } from './settings/settings.component';
import { CommonModule } from '@angular/common';
import { SettingsService } from './service/settings.service';
import { settingsReducer } from './service/reducer';
import { IonicModule } from '@ionic/angular';
import { StoreModule } from '@ngrx/store';
import { ListModule } from '../components/list.module';
import { PageLayoutModule } from '../page-layout';
import { TranslateModule } from '@ngx-translate/core';
import { NgLetModule } from 'ng-let';
import { DevicesComponent } from './devices/devices.component';
import { RelativeDateModule } from '../relative-date';

const SETTINGS_COMPONENTS = [SettingsComponent, DevicesComponent];
@NgModule({
    declarations: [...SETTINGS_COMPONENTS],
    exports: [...SETTINGS_COMPONENTS],
    imports: [
        CommonModule,
        IonicModule,
        StoreModule.forFeature('settings', settingsReducer),
        ListModule,
        PageLayoutModule,
        TranslateModule,
        NgLetModule,
        RelativeDateModule
    ],
    providers: [SettingsService]
})
export class SettingsModule {}

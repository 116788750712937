import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { ArmyBuilderConfig, SettingsService } from 'army-builder-shared';
import { map } from 'rxjs/operators';
import { SubscriptionService } from './subscription.service';

@Component({
    selector: 'wl-subscribe',
    template: `
        <ng-container *ngLet="{ checkoutStatus: checkoutStatus$ | async, hasAccess: userHasAccess$ | async } as props">
            <ion-card class="success" *ngIf="props.checkoutStatus === 'success'" color="success">
                <ion-card-content>
                    {{ 'GLOBAL.SUBSCRIPTION.CHECKOUT.SUCCESS' | translate }}
                </ion-card-content>
            </ion-card>

            <ion-card class="success" *ngIf="props.checkoutStatus === 'cancelled'" color="danger">
                <ion-card-content>
                    {{ 'GLOBAL.SUBSCRIPTION.CHECKOUT.CANCELLED' | translate }}
                </ion-card-content>
            </ion-card>
            <ng-container *ngIf="props.checkoutStatus !== 'success' && props.hasAccess === false">
                <form
                    *ngIf="stripeCheckout"
                    action="{{ config.apiBaseUrl }}/purchase/createCheckoutSession"
                    method="POST"
                >
                    <input type="hidden" name="priceId" value="{{ stripeProductKey }}" />
                    <ion-button type="submit"> {{ 'GLOBAL.MENU_ITEMS.SUBSCRIBE' | translate }}</ion-button>
                </form>
                <div class="buttons" *ngIf="!stripeCheckout">
                    <ion-button [routerLink]="'/subscribe'">{{ 'GLOBAL.MENU_ITEMS.SUBSCRIBE' | translate }}</ion-button>
                </div>
            </ng-container>
        </ng-container>
    `,
    styles: [
        `
            form {
                display: flex;
                width: 100%;
            }

            ion-button {
                flex: 1 1 auto;
            }

            ion-card {
                margin-inline: 2px;
            }

            .buttons {
                padding: 0;
            }
        `
    ]
})
export class SubscribeComponent {
    userHasAccess$ = this.subscriptionService.userHasAccess$;
    stripeProductKey = this.config.products[0].stripeKey;
    checkoutStatus$ = this.route.queryParams.pipe(map((p) => p.checkoutStatus?.toLowerCase()));
    stripeCheckout = this.platform.is('mobileweb') || this.platform.is('desktop');

    constructor(
        public config: ArmyBuilderConfig,
        private route: ActivatedRoute,
        private platform: Platform,
        private subscriptionService: SubscriptionService
    ) {}
}

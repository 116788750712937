import { Product } from '../model';
import { createAction, props, createSelector, createReducer, on, Action, State } from '@ngrx/store';

export interface PurchasesStateModel {
    products: Product[];
    purchases: string[];
    status: { [key: string]: string };
    error: string;
    restoringPurchases: boolean;
}

let initialState: PurchasesStateModel = {
    products: [],
    purchases: [],
    status: {},
    error: null,
    restoringPurchases: false
};

export const AddProduct = createAction('[Purchase] Add Product', props<{ product: Product }>());
export const AddPurchase = createAction('[Purchase] Add Purchase', props<{ productId: string }>());
export const RemovePurchase = createAction('[Purchase] Remove Purchase', props<{ productId: string }>());
export const PurchasesLoading = createAction('[Purchase] Purchases Loading');
export const PurchasesLoaded = createAction('[Purchase] Purchases Loaded');
export const ResetPurchases = createAction('[Purchase] Reset Purchases');
export const SetProductsStatus = createAction('[Purchase] Set Products Status', props<{ productId: string; status: string }>());
export const InitPurchaseError = createAction('[Purchase] Init Purchase Error', props<{ message: string }>());

export const selectPurchasing = (state: any) => {
    return state.purchase;
};
export const selectProducts = createSelector(selectPurchasing, (s: PurchasesStateModel) => s.products);
export const selectPurchases = createSelector(selectPurchasing, (s: PurchasesStateModel) => s.purchases);
export const selectStatus = createSelector(selectPurchasing, (s: PurchasesStateModel) => Object.values(s.status));
export const selectError = createSelector(selectPurchasing, (s: PurchasesStateModel) => s.error);
export const selectRestoringPurchases = createSelector(selectPurchasing, (s: PurchasesStateModel) => s.restoringPurchases);

export function purchaseReducer(state: PurchasesStateModel | undefined, action: Action) {
    return createReducer<PurchasesStateModel>(
        initialState,
        on(AddProduct, (state, { product }) => ({
            ...state,
            products: [...state.products.filter((p) => p.key !== product.key), product]
        })),
        on(AddPurchase, (state, { productId }) => ({
            ...state,
            purchases: [...state.purchases.filter((p) => p !== productId), productId]
        })),
        on(RemovePurchase, (state, { productId }) => ({
            ...state,
            purchases: [...state.purchases.filter((p) => p !== productId)]
        })),
        on(ResetPurchases, (state) => ({
            ...state,
            purchases: []
        })),
        on(SetProductsStatus, (state, { productId, status }) => ({
            ...state,
            status: { ...state.status, [productId]: status }
        })),
        on(InitPurchaseError, (state, { message }) => ({
            ...state,
            error: message
        })),
        on(PurchasesLoading, (state) => ({
            ...state,
            restoringPurchases: true
        })),
        on(PurchasesLoaded, (state) => ({
            ...state,
            restoringPurchases: false
        }))
    )(state, action);
}

import { Component } from '@angular/core';
import { Unit, Force } from '../../models';
import { ArmyBuilderConfig } from '../../../global/config';
import { ActivatedRoute, Router } from '@angular/router';
import { ForceService } from '../../force.service';
import { UnitTemplateLibrary } from '../../../global/unit-library';
import { UnitOptionLibrary } from '../../../global/unit-option-library';
import { AlertService } from '../../../global/alert/alert';
import { InputAlert } from '../../../global/input-alert/input-alert';
import { TranslateService } from '@ngx-translate/core';
import { ForceUtils } from '../../force.utils';
import { NumberPicker } from '../../../global/number-picker/number-picker';
import { ForceTypeLibrary, Modal, snapshot } from '../../../global';
import { SettingsService } from '../../../global/settings';
import { AlertController, ModalController, NavController } from '@ionic/angular';

@Component({
    selector: 'abs-unit',
    template: `
        <div *ngIf="unit$ | async as unit">
            <abs-page-layout (titleClicked)="renameUnit(unit)" [title]="unit.name" [subTitle]="unit.cost + ('GLOBAL.PTS' | translate)">
                <div class="buttons">
                    <ion-button class="addOption" [routerLink]="getOptionLink()">{{ 'FORCES.BUTTONS.ADD_OPTIONS' | translate }}</ion-button>
                    <ion-button class="details" [routerLink]="getDetailsLink()">{{ 'FORCES.BUTTONS.UNIT_DETAILS' | translate }}</ion-button>
                </div>
                <abs-list [items]="unit.upgrades" [template]="upgradeTemplate" (deleteItem)="deleteOption($event.item)"></abs-list>
            </abs-page-layout>
        </div>

        <ng-template #upgradeTemplate let-item="item">
            <div class="itemWrap">
                <div class="itemName">
                    <strong>{{ item.name }}</strong>
                </div>
                <div class="itemCost">{{ item.cost }}{{ 'GLOBAL.PTS' | translate }}</div>
                <div class="itemDescription">{{ item.description }}</div>
            </div>
        </ng-template>
    `
})
export class UnitPage {
    forces$ = this.forceService.force$;
    forceId$ = this.forceService.forceId$;
    unitId$ = this.forceService.unitId$;
    force$ = this.forceService.force$;
    units$ = this.forceService.units$;
    unit$ = this.forceService.unit$;
    options$ = this.forceService.options$;
    shared$ = this.forceService.shared$;

    constructor(
        public forceService: ForceService,
        public forceUtils: ForceUtils,
        public forceTypeLibrary: ForceTypeLibrary,
        public unitLibrary: UnitTemplateLibrary,
        public UnitOptionLibrary: UnitOptionLibrary,
        public route: ActivatedRoute,
        public router: Router,
        public config: ArmyBuilderConfig,
        public alertService: AlertService,
        public alertController: AlertController,
        public modal: Modal,
        public modalController: ModalController,
        public inputAlert: InputAlert,
        public translateService: TranslateService,
        public numberPicker: NumberPicker,
        public navController: NavController,
        public settingsService: SettingsService
    ) {}

    renameUnit(unit: Unit) {
        let title = this.translateService.instant('FORCES.DIALOGS.RENAME_UNIT.TITLE');
        let message = this.translateService.instant('FORCES.DIALOGS.RENAME_UNIT.MESSAGE');
        this.inputAlert.show(title, message, unit.customName, 'text', true).then((name) => {
            this.updateUnit({
                ...unit,
                customName: name
            });
        });
    }

    updateUnit(unit: Unit) {
        snapshot(this.force$, (force) => {
            const updatedForce = {
                id: force.id,
                units: [
                    ...force.units.map((u) => {
                        if (u.id === unit.id) {
                            return { ...unit };
                        }
                        return { ...u };
                    })
                ]
            };
            this.updateForce(updatedForce);
        });
    }

    updateForce(force: Partial<Force> & { id: string }) {
        this.forceService.update(force);
    }

    deleteOption(upgrade: any) {
        snapshot(this.force$, (force) => {
            this.forceService.update({
                id: force.id,
                units: force.units.map((unit) => ({
                    ...unit,
                    upgrades: unit.selectedOptions.filter((_u, i) => unit.selectedOptions.indexOf(upgrade) !== i)
                }))
            });
        });
    }

    getOptionLink() {
        return 'options';
    }

    getDetailsLink() {
        return 'details';
    }
}

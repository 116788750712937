import { SubscriptionComponent } from './subscription.component';
import { Routes } from "@angular/router";

export const SUBSCRIPTION_ROUTES: Routes = [
    {
        path: 'subscribe',
        component: SubscriptionComponent,
        pathMatch: 'full'
    }
];

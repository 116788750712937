import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, timer } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { NativeStorageService, resetAppState } from '../data';
import { markLoginChecked, SettingsService } from '../settings';
import { ArmyBuilderConfig } from '../config';
import { snapshot } from '../utils';
import { DataSyncService } from '../sync';
import { HttpClientWithInFlightCache } from '../httpClient';
import { LoginSelector } from '../settings/service/selectors';
import { Router } from '@angular/router';
import { AlertService } from '../alert/alert';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class UserService {
    login$ = this.settingsService.login$;
    loginChecked$ = this.settingsService.loginChecked$;
    private userIsLoggedIn = false; // Used to check if the user was just logged out
    constructor(
        protected httpClient: HttpClientWithInFlightCache,
        private settingsService: SettingsService,
        private store: Store,
        private syncService: DataSyncService,
        private storage: NativeStorageService,
        protected config: ArmyBuilderConfig,
        private router: Router,
        private alertService: AlertService,
        private translateService: TranslateService
    ) {
        setTimeout(() => {
            this.store
                .pipe(
                    select(LoginSelector),
                    filter((l) => l?.user?.accessToken),
                    map((loginData) => loginData.user?.id),
                    distinctUntilChanged()
                ) // Doesn't use settingsService.login$ because that won't resolve until checked = true
                .subscribe(async (user) => {
                    if (!user) {
                        return;
                    }

                    console.log('Checking active login following initial load');
                    this.checkActiveLogin(true).subscribe(() => {
                        this.store.dispatch(markLoginChecked({ checked: true }));
                    });
                });

            // Check every 5 minutes to make sure user login has not expired
            combineLatest([this.settingsService.login$, timer(0, 300000)])
                .pipe(
                    map(([l, _]) => l),
                    filter((l) => {
                        return l?.user?.accessToken;
                    }),
                    filter(() => document.hasFocus()),
                    map((loginData) => loginData.user?.id)
                )
                .subscribe(async (user) => {
                    if (!user) {
                        return;
                    }

                    console.log('Checking active login on timer');
                    this.checkActiveLogin(false)
                        .pipe(
                            catchError((err) => {
                                console.error(err);
                                return null;
                            })
                        )
                        .subscribe((isLoginActive) => {
                            if (!isLoginActive) {
                                this.router.navigate(['/']);
                            }
                        });
                });

            this.login$.subscribe((l) => {
                if (this.userIsLoggedIn && !l.user) {
                    this.router.navigateByUrl('/');
                }
                this.userIsLoggedIn = !!l.user;
            });
        }, 1000);
    }

    checkActiveLogin(syncAfterLogin = false): Observable<boolean> {
        return this.checkTokenRes().pipe(
            map((checkTokenRes) => {
                // Check if the login token has expired
                if (checkTokenRes === 401) {
                    this.logout('LOGIN_EXPIRED');
                } else if (checkTokenRes === 409) {
                    this.logout('LOGIN_EXPIRED');
                } else if (syncAfterLogin) {
                    // Sync on first login
                    console.log('checkActiveLogin: sync data');
                    this.syncService.sync();
                }

                return ![401, 409, 500].includes(checkTokenRes);
            })
        );
    }

    adminOnlyRoute(verb: 'get' | 'post' | 'delete' | 'put', url: string, payload?: any) {
        return this.login$.pipe(
            filter((l) => l.user?.roles?.includes(this.config.roles.admin)),
            switchMap((l) => {
                switch (verb) {
                    case 'post':
                        return this.httpClient.post(url, payload);
                    case 'put':
                        return this.httpClient.put(url, payload);
                    case 'get':
                        return this.httpClient.get(url);
                    case 'delete':
                        return this.httpClient.delete(url);
                }
            }),
            take(1)
        );
    }

    login(payload: { username?: string; password?: string; token?: string }) {
        let url = this.config.apiBaseUrl + '/auth/login';

        snapshot(this.settingsService.login$, (loginState) => {
            this.settingsService.updateValue('login', { ...loginState, loading: true });
            this.httpClient
                .post(url, payload, {
                    headers: { ...this.config.globalRequestHeaders },
                    withCredentials: true,
                    requiresLogin: false
                })
                .pipe(
                    take(1),
                    catchError((err) => of({ error: err.status }))
                )
                .subscribe((data: any) => {
                    const newLoginState = {
                        ...loginState,
                        loading: false,
                        checked: true,
                        error: data.error,
                        user: data.error ? undefined : data
                    };

                    this.settingsService.updateValue('login', newLoginState);
                    this.syncService.sync();
                });
        });
    }

    logout(error: string = null) {
        console.log('logout', error);
        this.clearUserData();
        snapshot(this.settingsService.login$, (loginState) => {
            this.settingsService.updateValue('login', { ...loginState, user: undefined, error });
            this.httpClient.post(this.config.apiBaseUrl + '/auth/log-out', {}).subscribe((res) => {
                console.log('Log out result:', res);

                // Temporarily disabled due to an intermittent refresh loop when logging out.
                // The setTimeout might fix this but I can't be sure right now. - Dec 9
                // setTimeout(() => window.location.href = '/', 1)
            });
        });
    }

    clearUserData() {
        this.storage.clear();
        this.store.dispatch(resetAppState());
    }

    private checkTokenRes(): Observable<number> {
        return this.config.device$.pipe(
            switchMap((device) =>
                this.login$.pipe(
                    // filter((l) => l?.user?.accessToken),
                    take(1),
                    map((l) => ({
                        Authorization: l.user ? 'Bearer ' + l.user.accessToken : null,
                        device: JSON.stringify(device)
                    })),
                    switchMap((authHeaders) =>
                        this.httpClient
                            .get(this.config.apiBaseUrl + '/auth/checkToken', {
                                headers: { ...this.config.globalRequestHeaders, ...authHeaders },
                                withCredentials: true
                                // observe: 'response'
                            })
                            .pipe(
                                catchError((err) => {
                                    if (err.status) {
                                        return of({ status: err.status });
                                    }
                                    return of({ status: 500 });
                                }),
                                map((res) => res.status)
                            )
                    )
                )
            )
        );
    }

    getStats(threshold: number) {
        const url = this.config.apiBaseUrl + '/user/stats/' + threshold;
        return this.httpClient.get(url).toPromise();
    }

    deleteAccount() {
        snapshot(this.settingsService.login$, (loginState) => {
            this.httpClient
                .delete(this.config.apiBaseUrl + '/userData', {})
                .pipe(
                    catchError((err) => {
                        console.error('Could not delete user account: ', err);
                        return of(null);
                    })
                )
                .subscribe((res) => {
                    console.log({ res });
                    if (res) {
                        this.logout();
                        window.location.href = '/';
                    } else {
                        this.alertService.showAlert(
                            this.translateService.instant('GLOBAL.SETTINGS.ACCOUNT.DELETE.ERROR.TITLE'),
                            this.translateService.instant('GLOBAL.SETTINGS.ACCOUNT.DELETE.ERROR.DESCRIPTION')
                        );
                    }
                });
        });
    }
}

import { Component } from '@angular/core';
import { PageLayoutComponent as BasePageLayoutComponent } from 'army-builder-shared';

@Component({
    selector: 'abs-page-layout',
    template: `
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button defaultHref=".." *ngIf="displayBackLink" (click)="navigateBack($event)"></ion-back-button>
                </ion-buttons>
                <ion-title>
                    <div class="title" (click)="titleClicked.emit()">{{ title }}</div>
                    <div class="subtitle" *ngIf="subTitle">{{ subTitle }}</div>
                </ion-title>
                <ion-buttons slot="end">
                    <ion-menu-button></ion-menu-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content #content fullscreen="true">
            <div class="wrap">
                <ng-content></ng-content>
            </div>
        </ion-content>
    `,
    styles: [
        `
            :host {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
            }

            .wrap {
                max-width: var(--wrapWidth, 600px);
                margin: 0 auto;
                padding-bottom: var(--ion-safe-area-bottom, 0);
            }
        `
    ]
})
export class PageLayoutComponent extends BasePageLayoutComponent {}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { GameListPage } from './home/game-list.page';
import { HomePage } from './home/home.page';
import { ContentPage } from './home/content.page';
import { UserGuard } from './user.guard';
import { SupportIssuesPage } from 'army-builder-shared';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomePage,
        data: {
            gameId: 'home'
        }
    },
    {
        path: 'pages/:pageId',
        component: ContentPage
    },
    {
        path: 'games',
        component: GameListPage,
        canActivate: [UserGuard],
        data: {
            gameId: 'home'
        }
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
        data: {
            gameId: 'home'
        }
    },
    {
        path: 'bolt-action',
        canActivate: [UserGuard],
        loadChildren: () => import('./bolt-action/bolt-action.module').then((m) => m.BoltActionModule),
        data: {
            gameId: 'bolt-action'
        }
    },
    {
        path: 'black-seas',
        canActivate: [UserGuard],
        loadChildren: () => import('./black-seas/black-seas.module').then((m) => m.BlackSeasModule),
        data: {
            gameId: 'black-seas'
        }
    },
    {
        path: 'blood-red-skies',
        canActivate: [UserGuard],
        loadChildren: () => import('./blood-red-skies/blood-red-skies.module').then((m) => m.BloodRedSkiesModule),
        data: {
            gameId: 'blood-red-skies'
        }
    },
    {
        path: 'settings',
        canActivate: [UserGuard],
        loadChildren: () => import('./settings/settings.module').then((m) => m.WarlordSettingsModule),
        data: {
            gameId: 'home'
        }
    },
    {
        path: 'subscription',
        loadChildren: () => import('./subscription/subscription.module').then((m) => m.SubscriptionModule),
        data: {
            gameId: 'home'
        }
    },
    {
        path: 'support',
        canActivate: [UserGuard],
        component: SupportIssuesPage,
        data: {
            gameId: 'home',
            mode: 'user'
        }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule {}

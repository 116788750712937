import { Component, HostListener, Input, TemplateRef } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'abs-modal-component',
    template: `
        <div [class]="cssClass">
            <ng-template #defaultContentTemplate>
                <p>Missing contentTemplate property for Modal config.</p>
            </ng-template>
            <ng-template #defaultButtonsTemplate>
                <ion-button (click)="dismiss()">Close</ion-button>
            </ng-template>
            <ion-card class="modal" (click)="preventClose($event)">
                <ion-card-header>
                    <ion-card-title>{{ title }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    <ng-template
                        [ngTemplateOutlet]="contentTemplate || defaultContentTemplate"
                        [ngTemplateOutletContext]="{ data: data }"
                    ></ng-template>
                </ion-card-content>
            </ion-card>
            <div class="buttons buttons-{{ buttonsDirection }}">
                <ng-template [ngTemplateOutlet]="buttonsTemplate || defaultButtonsTemplate"></ng-template>
            </div>
        </div>
    `,
    styles: [
        `
            :host {
                background: none;
                padding-top: var(--ion-safe-area-top, 20px);
                padding-bottom: var(--ion-safe-area-bottom, 20px);
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            ion-card {
                overflow-y: auto;
            }

            .buttons,
            ion-card {
                width: 95vw;
                max-width: 600px;
                margin-left: auto;
                margin-right: auto;
            }

            .buttons {
                display: flex;
                padding: 0;
                margin-bottom: 10px;
            }

            .buttons-vertical {
                flex-direction: column;
            }

            :host ::ng-deep .buttons-vertical ion-button {
                flex: 0 1 auto;
                width: 100%;
            }

            .buttons-horizontal {
                flex-direction: row;
            }

            .buttons ion-button,
            .buttons button,
            .buttons a {
                margin: 0 var(--ion-safe-area-bottom, 20px) var(--ion-safe-area-bottom, 20px);
                flex: auto;
                width: 100%;
            }

            .modal {
                max-height: 80vh;
            }
        `
    ]
})
export class ModalComponent {
    @Input()
    title: string = '';

    @Input()
    contentTemplate: TemplateRef<any>;

    @Input()
    buttonsTemplate: TemplateRef<any>;

    @Input()
    data: any;

    @Input()
    beforeDismiss: Function;

    @Input()
    afterDismiss: Function;

    @Input()
    buttonsDirection: 'horizontal' | 'vertical' = 'vertical';

    @Input()
    cssClass = '';

    @HostListener('click')
    onClick() {
        this.modalController.dismiss();
    }

    preventClose(e) {
        e.stopPropagation();
    }

    dismiss(...args: any[]) {
        if (this.beforeDismiss) {
            let shouldDismiss = this.beforeDismiss(...args);
            if (shouldDismiss === false) {
                return;
            }
        }
        this.modalController.dismiss(...args);
        if (this.afterDismiss) {
            this.afterDismiss();
        }
    }

    constructor(private modalController: ModalController) {}
}

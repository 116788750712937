import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ArmyBuilderConfig, PurchasingService, SettingsService } from 'army-builder-shared';
import { map } from 'rxjs/operators';
import { SubscriptionService } from './subscription.service';

@Component({
    selector: 'wl-subscription',
    template: `
        <abs-page-layout
            [title]="'GLOBAL.SUBSCRIPTION.TITLE' | translate"
            *ngLet="{
                subscriptionStatus: subscriptionStatus$ | async
            } as props"
        >
            <wl-manage-subscription></wl-manage-subscription>

            <!-- <div class="buttons">
                <ion-button (click)="restorePurchases()">{{ 'GLOBAL.PURCHASES.RESTORE' | translate }}</ion-button>
            </div> -->

            <ng-container *ngIf="props.subscriptionStatus !== 'active'">
                <ion-list class="transparent products" *ngIf="(products$ | async).length > 0">
                    <ion-item *ngFor="let product of products$ | async; let i = index" lines="full" [class.alt]="i % 2 === 0">
                        <div class="product">
                            <h2 class="itemName">
                                {{ product.name }}
                            </h2>
                            <p class="itemDescription">
                                {{ product.description }}
                            </p>
                            <div class="buttons">
                                <ng-container *ngIf="(purchases$ | async).indexOf(product.key) === -1; else owned">
                                    <ion-button (click)="purchase(product.key)" *ngIf="!enableStripeCheckout">
                                        {{ 'GLOBAL.SUBSCRIPTION.BUY' | translate }} - {{ product.price }}
                                    </ion-button>

                                    <wl-subscribe></wl-subscribe>
                                </ng-container>

                                <ng-template #owned>
                                    <ion-button [disabled]="true" *ngIf="(purchases$ | async).indexOf(product.key) > -1">{{
                                        'GLOBAL.SUBSCRIPTION.OWNED' | translate
                                    }}</ion-button>
                                </ng-template>
                            </div>
                        </div>
                    </ion-item>
                </ion-list>
            </ng-container>
        </abs-page-layout>
    `
})
export class SubscriptionComponent {
    public purchases$ = this.purchasingSvc.purchases$;
    public products$ = this.purchasingSvc.products$;
    public status$ = this.purchasingSvc.status$;

    public debug = false;

    enableStripeCheckout = !this.platform.is('cordova');

    subscriptionStatus$ = this.subscriptionService.subStatus$;

    constructor(
        public purchasingSvc: PurchasingService,
        private platform: Platform,
        public config: ArmyBuilderConfig,
        private subscriptionService: SubscriptionService
    ) {}

    purchase(productId: string) {
        this.purchasingSvc.purchase(productId);
    }

    restorePurchases() {
        this.purchasingSvc.restorePurchases();
    }
}

import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Force, PointsAdjustment, Unit } from '../../models';
import { ForceService } from '../../force.service';
import { combineLatest, tap } from 'rxjs';
import { snapshot } from '../../../global/utils';
import { Modal } from '../../../global/modal';
import { TranslateService } from '@ngx-translate/core';
import { IonInput } from '@ionic/angular';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'points-adjustments',
    template: `
        <ng-container
            *ngLet="{
                unit: unit$ | async,
                force: force$ | async
            } as props"
        >
            <ng-template #itemTemplate let-item="item">
                <div class="itemDescription">{{ item.description }}</div>
                <div class="itemCost">{{ item.cost }}pts</div>
            </ng-template>
            <ng-container *ngIf="props.unit.pointsAdjustments?.length > 0">
                <h2>{{ 'GLOBAL.POINTS_ADJUSTMENT.TITLE' | translate }}</h2>
                <abs-list
                    [items]="props.unit.pointsAdjustments"
                    [template]="itemTemplate"
                    [showDelete]="true"
                    (deleteItem)="removePointsAdjustment($event)"
                >
                </abs-list>
            </ng-container>
            <div class="buttons">
                <ion-button (click)="showPointsAdjustmentForm()">{{ 'GLOBAL.POINTS_ADJUSTMENT.ADD' | translate }}</ion-button>
            </div>

            <ng-template #adjustmentForm>
                <p>{{ 'GLOBAL.POINTS_ADJUSTMENT.MODAL.DESCRIPTION' | translate }}</p>

                <form [formGroup]="form">
                    <p class="form-row">
                        <ion-label>{{ 'GLOBAL.POINTS_ADJUSTMENT.MODAL.NAME' | translate }}</ion-label>
                        <ion-input #pointsAdjustDescription formControlName="description"></ion-input>
                    </p>

                    <p class="form-row">
                        <ion-label>{{ 'GLOBAL.POINTS_ADJUSTMENT.MODAL.COST' | translate }}</ion-label>
                        <ion-input [type]="'number'" #pointsAdjustCost formControlName="cost"></ion-input>
                    </p>
                </form>
            </ng-template>
            <ng-template #adjustmentFormButtons>
                <ion-button [disabled]="form.invalid" (click)="addPointsAdjustment()">{{
                    'GLOBAL.POINTS_ADJUSTMENT.MODAL.SAVE' | translate
                }}</ion-button>
            </ng-template>
        </ng-container>
    `
})
export class UnitPoinstAdjustmentsComponent {
    force$ = this.forceService.force$;
    unit$ = this.forceService.unit$;

    form: FormGroup;

    @ViewChild('adjustmentForm')
    adjustmentForm: TemplateRef<any>;

    @ViewChild('adjustmentFormButtons')
    adjustmentFormButtons: TemplateRef<any>;

    @ViewChild('pointsAdjustDescription')
    pointsAdjustDescription: IonInput;

    @ViewChild('pointsAdjustCost')
    pointsAdjustCost: IonInput;

    constructor(
        private forceService: ForceService,
        private modal: Modal,
        private translateService: TranslateService,
        private fb: FormBuilder
    ) {
        this.form = this.fb.group({
            description: this.fb.control([], { validators: [Validators.required] }),
            cost: this.fb.control([], { validators: [Validators.required] })
        });
    }

    addPointsAdjustment() {
        if (!this.form.valid) {
            return;
        }
        let pa: PointsAdjustment = this.form.getRawValue();

        snapshot(combineLatest([this.force$, this.unit$]), ([force, unit]) => {
            this.forceService.addPointsAdjustmentToUnit(force.id, unit.id, pa);
        });
    }

    showPointsAdjustmentForm() {
        this.modal.show({
            props: {
                contentTemplate: this.adjustmentForm,
                buttonsTemplate: this.adjustmentFormButtons,
                title: this.translateService.instant('GLOBAL.POINTS_ADJUSTMENT.MODAL.TITLE')
            }
        });
    }

    removePointsAdjustment(event) {
        snapshot(combineLatest([this.force$, this.unit$]), ([force, unit]) => {
            this.forceService.removePointsAdjustmentFromUnit(force.id, unit.id, event.i);
        });
    }
}

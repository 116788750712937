import { Injectable } from '@angular/core';
import { Router, RoutesRecognized, ActivatedRouteSnapshot } from '@angular/router';
import { map, filter, shareReplay, defaultIfEmpty, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RouterService {
    route$: Observable<ActivatedRouteSnapshot> = this.router.events.pipe(
        filter((e) => !!e),
        filter((e) => e instanceof RoutesRecognized),
        map((e) => {
            let val = (e as RoutesRecognized).state.root;
            while (val.firstChild) {
                val = val.firstChild;
            }
            return val;
        }),
        shareReplay(1)
    );

    // https://stackoverflow.com/questions/45737375/angular-activatedroute-data-returns-an-empty-object
    routeData$: Observable<any> = this.route$.pipe(
        map((e: ActivatedRouteSnapshot) => ({ ...e.data })),
        // filter((e) => !!e)
        defaultIfEmpty({})
    );

    primaryRouteParams$ = this.route$.pipe(
        filter((r) => r.outlet === 'primary'),
        map((r) => r.params)
    );
    selectedUnitId$ = this.primaryRouteParams$.pipe(map((p) => p.unitId));
    forceId$ = this.primaryRouteParams$.pipe(map((p) => p.forceId));

    constructor(private router: Router) {}
}

import { Injectable } from '@angular/core';
import {
    entitySyncComplete,
    startEntitySync,
    SelectSyncStatus,
    entitySyncError,
    removeEntityFromChangeCache,
    EntityChangeSelector
} from '../data';
import { BehaviorSubject, Observable, combineLatest, timer } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import { SyncConflictComponent } from './sync-conflict.component';
import { BaseSyncService } from './sync.base';
import { Force } from '../../forces';
import { snapshot } from '../utils';
import { selectForces } from '../../forces/state/selectors';
import { upsertForce } from '../../forces/state/actions';

const mapToForceId = (f) => f.forceId;
const getForceIdsByState = (forces, state) => forces.filter((f) => f.state === state).map(mapToForceId);
export interface UserDataSyncStatus {
    status: 'SYNCING' | 'IDLE' | 'ERROR';
    message?: string;
}

const SYNC_DEBOUNCE = 10000;

@Injectable({ providedIn: 'root' })
export class ForceSyncService extends BaseSyncService {
    entityType = 'forces';
    syncStatus$ = new BehaviorSubject<UserDataSyncStatus>({ status: 'IDLE' });

    forceCache$: Observable<Force[]> = this.store.select(selectForces).pipe(shareReplay(1));
    entityChangeCache$ = this.store.select(EntityChangeSelector).pipe(
        map((s) => s.forces),
        distinctUntilChanged(),
        map((forces) => Object.entries(forces || {}).map((f) => ({ forceId: f[0], state: f[1] }))),
        shareReplay(1)
    );
    deletedForces$ = this.entityChangeCache$.pipe(map((forces) => getForceIdsByState(forces, 'Deleted')));
    dirtyForces$ = this.entityChangeCache$.pipe(map((forces) => getForceIdsByState(forces, 'Dirty')));
    cleanForces$ = this.entityChangeCache$.pipe(map((forces) => getForceIdsByState(forces, 'Clean')));
    erroredForces$ = this.entityChangeCache$.pipe(map((forces) => getForceIdsByState(forces, 'Error')));

    init() {
        setTimeout(() => {
            this.deletedForces$.pipe(debounceTime(SYNC_DEBOUNCE)).subscribe((toDelete) => this.uploadForces({ toSync: [], toDelete }));
            this.dirtyForces$
                .pipe(
                    switchMap((forceIds) => this.forceCache$.pipe(map((forces) => forces.filter((f) => forceIds.includes(f.id))))),
                    debounceTime(SYNC_DEBOUNCE)
                )
                .subscribe((toSync) => this.uploadForces({ toSync, toDelete: [] }));
        }, 1000);
    }

    sync() {
        this.syncStatus$.next({ status: 'SYNCING', message: 'Syncing forces' });
        snapshot(this.getForcesFromServer(), (remoteForces) => {
            this.syncStatus$.next({ status: 'SYNCING', message: `${remoteForces?.length || 0} forces syncing from server` });

            snapshot(this.getForcesFromState(), (localForces) => {
                this.syncStatus$.next({ status: 'SYNCING', message: `${localForces?.length || 0} forces syncing from local` });

                const newForcesToDownload = remoteForces.filter((rf) => !localForces.find((lf) => lf.id === rf.id));

                newForcesToDownload.forEach((f, i, all) => {
                    this.syncStatus$.next({ status: 'SYNCING', message: `Adding force ${i + 1} of ${all.length}` });
                    this.addForceToState(f);
                });

                this.syncStatus$.next({ status: 'SYNCING', message: `Uploading ${localForces.length} forces to server` });

                this.uploadForces({
                    toSync: localForces,
                    toDelete: []
                });
            });
        });
    }

    uploadForces(forces: { toSync: Force[]; toDelete: string[] }) {
        forces.toSync.forEach((force) => this.syncForce(force));
        forces.toDelete.forEach((forceId) => {
            let url = `${this.config.apiBaseUrl}/userData/forces/${forceId}`;
            let entityChangeCachePayload = { entityType: 'forces', entityId: forceId };
            this.httpClient
                .delete(url, {
                    headers: { ...this.config.globalRequestHeaders },
                    withCredentials: true,
                    requiresLogin: true
                })
                .pipe(
                    catchError((_err) => {
                        console.error('entitySyncError', _err);
                        this.store.dispatch(entitySyncError(entityChangeCachePayload));
                        return null;
                    }),
                    filter((x) => !!x)
                )
                .subscribe((res) => {
                    console.log('removing ' + forceId + ' from cache');
                    this.store.dispatch(removeEntityFromChangeCache(entityChangeCachePayload));
                });
        });
    }

    getForcesFromServer(): Observable<Force[]> {
        const url = `${this.config.apiBaseUrl}/userData/forces`;
        const records = this.httpClient
            .get(url, {
                headers: { ...this.config.globalRequestHeaders },
                withCredentials: true,
                requiresLogin: true
            })
            .pipe(
                withLatestFrom(this.deletedForces$),
                map(([data, deletedForces]) =>
                    data
                        .map((r) => r.data)
                        .filter((x) => {
                            // TODO: why is this not working?
                            let deletedForceIds = deletedForces.map((df) => df.id);
                            return !deletedForceIds.includes(x.id);
                        })
                )
            );
        return records;
    }

    private getForcesFromState() {
        return this.store.select(selectForces).pipe(
            map((forces) => {
                return [
                    ...forces.map((f: Force) => {
                        let units = f.units;
                        if (!units && (f as any).data?.units) {
                            // Fixes a data bug caused by the old sync process
                            units = (f as any).data.units;
                        }

                        if (!units && (f as any).changes?.units) {
                            // Fixes a data bug caused by the old sync process
                            units = (f as any).changes.units;
                        }

                        return {
                            ...f,
                            units: units.map((u) => ({
                                ...u,
                                unitTemplate: undefined
                            }))
                        };
                    })
                ].filter((f) => f);
            })
        );
    }

    syncForce(_force: any) {
        let force = {
            ..._force,
            appVersion: this.config.version,
            units: _force.units.map((unit) => {
                let u = structuredClone(unit);
                delete u.unitTemplate;
                return u;
            })
        };
        snapshot(this.getForceStatus(force), async (forceStatus) => {
            if (forceStatus === 'InProgress') {
                return;
            }

            this.start('forces', force.id);

            const lastSyncTime = force.updatedAt || 0;
            const existsOnServer = lastSyncTime > 0;

            if (!existsOnServer) {
                this.uploadForce(force);
                return;
            }

            const serverTimestamp = await this.getServerTimestamp(force).toPromise();

            const changesToUpload = forceStatus === 'Dirty';
            const changesToDownload = lastSyncTime < serverTimestamp;

            if (lastSyncTime > serverTimestamp && serverTimestamp !== 0) {
                console.log('Handling sync conflict (timestamp mismatch)', force.id);
                this.handleConflict(force, serverTimestamp);
            } else if (changesToDownload && changesToUpload) {
                console.log('Handling sync conflict (changes on both)', force.id);
                this.handleConflict(force, serverTimestamp);
            } else if (changesToUpload) {
                console.log('Uploading force', force.id);
                this.uploadForce(force);
            } else if (changesToDownload) {
                console.log('Downloading force', force.id);
                this.downloadForce(force);
            } else {
                // Everything is up to date
                // Dispatch a message?
                this.complete('forces', force.id);
            }
        });
    }

    private async handleConflict(force: any, serverTimestamp: number) {
        const modal = await this.modalController.create({
            component: SyncConflictComponent,
            componentProps: {
                localTimestamp: force.updatedAt,
                remoteTimestamp: serverTimestamp,
                force
            }
        });
        modal.present();
        this.error('forces', force.id);
    }

    protected start(entityType: string, entityId?: string) {
        this.store.dispatch(startEntitySync({ entityType, entityId }));
    }

    protected error(entityType: string, entityId: string) {
        setTimeout(() => {
            console.log('entitySyncError', { entityType, entityId });
            this.store.dispatch(entitySyncError({ entityType, entityId }));
        }, 500);
    }

    protected complete(entityType: string, entityId: string) {
        setTimeout(() => {
            this.store.dispatch(entitySyncComplete({ entityType, entityId }));
            snapshot(this.dirtyForces$, (dirtyForces) => {
                if (dirtyForces.length === 0) {
                    this.syncStatus$.next({ status: 'IDLE', message: null });
                }
            });
        }, 500);
    }

    protected getForceUrl(id?: string) {
        return `${this.config.apiBaseUrl}/userData/forces/${id || ''}`;
    }

    addForceToState(force: Force) {
        snapshot(combineLatest([this.dirtyForces$, this.deletedForces$]), ([_dirtyForces, deletedForces]) => {
            if (deletedForces.includes(force.id)) {
                return;
            }

            let action = upsertForce({ force });
            this.store.dispatch(action);
            this.complete('forces', force.id);
        });
    }

    handleSyncError(forceId: string) {
        return catchError((err) => {
            this.error('forces', forceId);
            throw err;
        });
    }

    downloadForce(force: any) {
        let headers = this.config.globalRequestHeaders;
        this.httpClient
            .get(this.getForceUrl(force.id), { headers, withCredentials: true, requiresLogin: true })
            .pipe(this.handleSyncError(force.id))
            .subscribe((serverData: { data: any }) => {
                this.addForceToState(serverData.data);
            });
    }

    uploadForce(force: any) {
        let headers = this.config.globalRequestHeaders;
        let promise: Observable<number>;
        if (force.updatedAt) {
            promise = this.httpClient.put(this.getForceUrl(force.id), force, {
                headers,
                withCredentials: true,
                requiresLogin: true
            });
        } else {
            promise = this.httpClient.post(this.getForceUrl(), force, {
                headers,
                withCredentials: true,
                requiresLogin: true
            });
        }

        promise.pipe(this.handleSyncError(force.id)).subscribe((newTimestamp) => {
            let action = upsertForce({
                force: {
                    ...force,
                    updatedAt: newTimestamp
                }
            });
            this.store.dispatch(action);
            this.complete('forces', force.id);
        });
    }

    // protected getUpdateAction(force: Force) {
    //     return upsertForce({ force });
    // }

    protected getServerTimestamp(force: any): Observable<number> {
        let headers = this.config.globalRequestHeaders;
        return this.httpClient.get(`${this.getForceUrl(force.id)}/timestamp`, {
            headers,
            withCredentials: true,
            requiresLogin: true
        });
    }

    protected getForceStatus(force: any) {
        return this.store.select(SelectSyncStatus, { entityType: 'forces', id: force.id });
    }

    protected getSettingsStatus() {
        return this.store.select(SelectSyncStatus, { entityType: 'settings' });
    }
}

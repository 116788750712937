import { createReducer, on, Action } from '@ngrx/store';
import { selectLanguage, updateSettingsState, updateSettingsValue } from './actions';
import { LoginState } from '../models';
import { markLoginChecked } from '../..';

export interface SettingsState {
    login: LoginState;
    selectedLanguage: string;
}

export const defaultLoginState = {
    user: null,
    error: null,
    loading: false,
    checked: false
};

export const initialSettingsState: SettingsState = {
    login: defaultLoginState,
    selectedLanguage: 'en'
};

export function settingsReducer(state: SettingsState | undefined, action: Action) {
    return createReducer(
        initialSettingsState,
        on(updateSettingsValue, (state: any, { settingsKey, newValue }) => ({
            ...state,
            [settingsKey]: typeof newValue === 'object' ? { ...state[settingsKey], ...newValue } : newValue
        })),
        on(updateSettingsState, (state: any, newState: SettingsState) => ({
            ...state,
            ...newState
        })),
        on(selectLanguage, (state: any, { language }) => ({
            ...state,
            selectedLanguage: language
        })),
        on(markLoginChecked, (state: any, { checked }) => ({
            ...state,
            login: {
                ...state.login,
                checked
            }
        }))
    )(state, action);
}

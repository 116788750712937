import { Injectable } from '@angular/core';
import { ForceSyncService } from './sync.force';
import { SettingsSyncService } from './sync.settings';
import { BaseSyncService } from './sync.base';

@Injectable({ providedIn: 'root' })
export class DataSyncService {
    constructor(private forceSyncService: ForceSyncService, private settingsSyncService: SettingsSyncService) {}

    customSyncServices: BaseSyncService[] = [];

    registerCustomSyncService(service: BaseSyncService) {
        this.customSyncServices.push(service);
    }

    sync() {
        this.forceSyncService.sync();
        this.settingsSyncService.sync();

        this.customSyncServices.forEach((s) => s.sync());
    }
}

import { Device as DevicePlugin } from '@capacitor/Device';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject, distinctUntilChanged, filter, shareReplay, tap } from 'rxjs';

let deviceSubject = new BehaviorSubject<Device>(null);

export const GLOBAL_API_REQUEST_HEADERS = {
    device: ''
};

export interface Device {
    id: string;
    friendlyName: string;
}

export const DEVICE$ = deviceSubject.pipe(
    filter((d) => !!d),
    distinctUntilChanged(),
    tap((device) => {
        // It's a bit weird putting this in a tap rather than in the subscribe,
        // But I want to make sure the header is assigned before any other
        // subscriptions trigger for this observable
        GLOBAL_API_REQUEST_HEADERS.device = JSON.stringify(device);
    }),
    shareReplay(1)
);
DEVICE$.subscribe();

console.log('getting device');

DevicePlugin.getInfo().then(async (deviceInfo) => {
    console.log('device: ', deviceInfo);
    let device = await getDeviceFromLocalStorage();

    if (!device) {
        let friendlyName = deviceInfo.name || getFriendlyNameFromUserAgent() || 'Unknown Device';
        let id = (await DevicePlugin.getId()).uuid;

        device = { id, friendlyName };
        saveDeviceToLocalStorage(device);
    }
    deviceSubject.next(device);
});

const saveDeviceToLocalStorage = (device: Device) => {
    Preferences.set({ key: 'device', value: JSON.stringify(device) });
};

const getDeviceFromLocalStorage = () =>
    new Promise<Device>((resolve, reject) => {
        console.log('checking for an existing device in local storage');

        setTimeout(() => {
            // It seems that sometimes localstorage isn't ready
            // on page load, so use setTimeout
            Preferences.get({ key: 'device' })
                .then((res) => {
                    let deviceId: string;
                    if (res.value) {
                        console.log('existing device: ', res.value);
                        deviceId = JSON.parse(res.value)?.id;
                    } else {
                        resolve(null);
                    }

                    let friendlyName = getFriendlyNameFromUserAgent();
                    resolve({ id: deviceId, friendlyName });
                })
                .catch((err) => {
                    console.error('Error getting device id from local storage.', err);
                    resolve(null);
                });
        }, 500);
    });

const getFriendlyNameFromUserAgent = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const browser =
        userAgent.indexOf('edge') > -1
            ? 'Edge'
            : userAgent.indexOf('edg') > -1
            ? 'Chromium Based Edge'
            : userAgent.indexOf('opr') > -1 && !!(window as any).opr
            ? 'Opera'
            : userAgent.indexOf('chrome') > -1 && !!(window as any).chrome
            ? 'Chrome'
            : userAgent.indexOf('trident') > -1
            ? 'Internet Explorer'
            : userAgent.indexOf('firefox') > -1
            ? 'Firefox'
            : userAgent.indexOf('safari') > -1
            ? 'Safari'
            : 'Unknown web browser';

    console.log(`Browser: ${userAgent.toLowerCase()}\n${browser}`);
    return browser;
};

import { Component, EventEmitter, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { IonContent, IonRouterOutlet, NavController } from '@ionic/angular';
import { BehaviorSubject, fromEvent, ReplaySubject, timer } from 'rxjs';
import { tap, takeUntil, map, filter } from 'rxjs/operators';
import { ScrollService } from '../scroll-service/scroll-service';
import { ThemeService } from './theme.service';
import { RouterService } from '../router.service';

@Component({
    selector: 'abs-page-layout',
    template: `
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-back-button defaultHref=".." *ngIf="displayBackLink" (click)="navigateBack($event)"></ion-back-button>
                </ion-buttons>
                <ion-title>
                    <div class="title" (click)="titleClicked.emit()">{{ title }}</div>
                    <div class="subtitle" *ngIf="subTitle">{{ subTitle }}</div>
                </ion-title>
                <ion-buttons slot="end">
                    <ion-menu-button></ion-menu-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content #content fullscreen="true">
            <div class="wrap">
                <ng-content></ng-content>
            </div>
        </ion-content>
    `,
    styles: [
        `
            :host {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
            }

            .wrap {
                max-width: var(--wrapWidth, 600px);
                margin: 0 auto;
                padding-bottom: var(--ion-safe-area-bottom, 0);
            }
        `
    ]
})
export class PageLayoutComponent implements OnInit {
    @Input() title: string;
    @Input() subTitle: string;
    @Input() displayBackLink: boolean = true;
    @ViewChild('content') content: IonContent;

    @Output() titleClicked = new EventEmitter();
    hasScrollParent = true;

    destroy$ = new ReplaySubject();
    resize$ = fromEvent(window, 'resize').pipe(takeUntil(this.destroy$));
    resizeSub = this.resize$.subscribe((e: any) => {
        this.width$.next(e.currentTarget.innerWidth);
    });
    width$ = new BehaviorSubject(window.innerWidth);

    routeData$ = this.routerService.routeData$;

    constructor(
        protected themeService: ThemeService,
        protected route: ActivatedRoute,
        protected scrollService: ScrollService,
        protected navCtrl: NavController,
        private routerService: RouterService,
        @Optional()
        protected routerOutlet: IonRouterOutlet
    ) {
        this.scrollService.reset(route.outlet);
    }

    ngOnInit(): void {
        this.route.data.subscribe((d) => {
            this.themeService.selectTheme(d.gameId || 'home');
        });
    }

    ngAfterViewInit() {
        this.initScroll();
        this.resize$.subscribe(() => {
            this.initScroll();
        });
    }

    initScroll() {
        if (this.hasScrollParent) {
            let cancelLoop$ = new ReplaySubject();
            timer(0, 200)
                .pipe(
                    tap((i) => {
                        if (i > 10) {
                            cancelLoop$.next(true);
                        }
                    }),
                    takeUntil(cancelLoop$),
                    takeUntil(this.scrollService.outlets[this.route.outlet].scrollEl$),
                    map(async () => await this.content),
                    filter((x) => !!x)
                )
                .subscribe(async () => {
                    if (!this.content) {
                        return;
                    }
                    this.content.scrollEvents = true;
                    this.scrollService.outlets[this.route.outlet].content$.next(await this.content);
                    this.scrollService.outlets[this.route.outlet].scrollEl$.next(await this.content.getScrollElement());
                });
        }
    }

    navigateBack(ev) {
        // if (!this.routerOutlet) {
        let startingPath = location.pathname;
        let urlParts = startingPath.split('/');
        this.navCtrl.setDirection('back');

        let tryParentRoute = () => {
            if (urlParts.length > 1) {
                urlParts.pop();
                let url = urlParts.join('/');

                console.log('Attempting to navigate to ' + url);
                return this.navCtrl
                    .navigateBack(url, { animationDirection: 'back' })
                    .then(() => {
                        if (location.pathname === startingPath) {
                            return tryParentRoute();
                        } else {
                            ev.preventDefault();
                        }
                    })
                    .catch(() => {
                        return tryParentRoute();
                    });
            } else {
                console.log('No ancestor route found.  Navigating to root.');
                return this.navCtrl.navigateBack('/', { animationDirection: 'back' });
            }
        };

        return tryParentRoute();
        // }
    }
}

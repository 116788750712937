import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { getValueFromTranslation, getValueFromTranslationSync, LANGUAGE } from '../../global/language/selectLanguage';
import { Unit, UnitTemplate } from '../models';

@Pipe({
    name: 'unitName'
})
export class UnitNamePipe implements PipeTransform {
    transform(unit: Unit | UnitTemplate): any {
        return getUnitName(unit);
    }
}

export const getUnitName = (unit: Unit | UnitTemplate): Observable<string> => {
    return of(getUnitNameSync(unit));
};

export const getUnitNameSync = (unit: Unit | UnitTemplate): string => {
    let unitLike: any = unit;
    if (!unitLike) {
        return null;
    }
    if (unitLike.unitTemplate) {
        let unitTemplate = unitLike.unitTemplate as UnitTemplate;

        let variant = unitTemplate.variants?.find((v) => v.id === unitLike.variantId);
        let variantName = variant?.name?.en ? variant.name : null;
        let option = unitLike.selectedOptions
            .map((soid) => unitTemplate.options.find((o) => parseInt(o.id as any) === parseInt(soid)))
            .find((x: any) => x?.nameOverride || x?.unitNameOverride?.[LANGUAGE]);
        let name = variantName || unitTemplate.name;
        let override = option?.unitNameOverride || option?.nameOverride;

        if (override && typeof override === 'object') {
            name = override;
        } else if (override) {
            name = option.name;
        }

        return unitLike.customName ? unitLike.customName : getValueFromTranslationSync(name);
    }

    return getValueFromTranslationSync(unitLike.name);
};

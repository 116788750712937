import { createAction, props } from '@ngrx/store';
import { SettingsState } from './reducer';

export const updateSettingsValue = createAction(
    '[Settings] updateSettingsValue',
    props<{ settingsKey: string; newValue: any }>()
);

export const updateSettingsState = createAction('[Settings] updateSettingsState', props<SettingsState>());
export const markLoginChecked = createAction('[Settings] markLoginChecked', props<{ checked: boolean }>());
export const selectLanguage = createAction('[Settings] selectLanguage', props<{ language: string }>());

import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { addForce, addPointsAdjustment, deleteForce, removePointsAdjustment, updateForce, upsertForce } from './actions';
import { markEntityAsDeleted, markEntityAsDirty } from '../../global';

@Injectable()
export class ForceEffects {
    constructor(private actions$: Actions, private store: Store<any>) {
        this.actions$.pipe(ofType(updateForce, addForce, upsertForce)).subscribe((changeData) => {
            this.store.dispatch(markEntityAsDirty({ entityType: 'forces', entityId: changeData.force.id }));
        });

        this.actions$.pipe(ofType(addPointsAdjustment, removePointsAdjustment)).subscribe((changeData) => {
            this.store.dispatch(markEntityAsDirty({ entityType: 'forces', entityId: changeData.forceId }));
        });

        this.actions$.pipe(ofType(deleteForce)).subscribe((changeData) => {
            this.store.dispatch(markEntityAsDeleted({ entityType: 'forces', entityId: changeData.id, updatedAt: changeData.updatedAt }));
        });
    }
}

import { Component, NgZone } from '@angular/core';
import { InputAlert } from '../../../global/input-alert/input-alert';
import { ArmyBuilderConfig } from '../../../global/config';
import { UnitTemplateLibrary } from '../../../global/unit-library';
import { ForceTypeLibrary } from '../../../global/force-type-library';
import { Force } from '../../models';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { snapshot } from '../../../global/utils';
import { ForceUtils } from '../../force.utils';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, ForceSyncService, HttpClientWithInFlightCache, Modal, SharingService } from '../../../global';
import { AlertController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { ForceService } from '../../force.service';

@Component({
    selector: 'abs-force-list',
    template: `
        <abs-page-layout [title]="'FORCES.HEADERS.FORCE_LIST' | translate" [displayBackLink]="false">
            <div class="buttons">
                <ion-button *ngIf="selectForceType$ | async; else addForceTemplate" [routerLink]="['new']">{{
                    'FORCES.BUTTONS.ADD_FORCE' | translate
                }}</ion-button>
                <ng-template #addForceTemplate>
                    <ion-button (click)="addForce()">{{ 'FORCES.BUTTONS.ADD_FORCE' | translate }}</ion-button>
                </ng-template>
            </div>
            <ng-template #forceRowTemplate let-item="item">
                <div class="itemName">
                    {{ item.name }}
                </div>
                <div class="itemCost">{{ item.cost }}{{ 'GLOBAL.PTS' | translate }}</div>
            </ng-template>
            <abs-list
                [items]="forces$ | async"
                [getRouterLink]="getRouterLink"
                [showDelete]="true"
                (deleteItem)="deleteForce($event.item)"
                [template]="forceRowTemplate"
            ></abs-list>
        </abs-page-layout>
    `
})
export class ForceListPage {
    selectForceType$ = this.route.data.pipe(map((d) => d.selectForceType));

    filterForces(force: Force) {
        return force.gameId === this.forceUtils.gameId;
    }
    forces$ = this.forceService.forces$.pipe(map((forces: Force[]) => forces.filter((f) => this.filterForces(f))));

    constructor(
        public forceService: ForceService,
        public inputAlert: InputAlert,
        public alertController: AlertController,
        public config: ArmyBuilderConfig,
        public unitLibrary: UnitTemplateLibrary,
        public translateService: TranslateService,
        public forceTypeLibrary: ForceTypeLibrary,
        public forceUtils: ForceUtils,
        public route: ActivatedRoute,
        public router: Router,
        public modal: Modal,
        public platform: Platform,
        public sharingService: SharingService,
        public store: Store,
        public zone: NgZone,
        public syncService: ForceSyncService
    ) {}

    addForce() {
        snapshot(
            combineLatest([
                this.translateService.get('FORCES.DIALOGS.NEW_FORCE.TITLE'),
                this.translateService.get('FORCES.DIALOGS.NEW_FORCE.MESSAGE'),
                this.translateService.get('FORCES.DIALOGS.NEW_FORCE.DEFAULT_FORCE_NAME')
            ]),
            ([title, message, defaultForceName]) => {
                this.inputAlert.show(title, message, defaultForceName, 'text', true).then((name) => {
                    if (!name) {
                        return;
                    }
                    const f: Force = {
                        id: undefined,
                        gameId: null,
                        faction: 'test',
                        name,
                        units: [],
                        cost: 0,
                        platoons: []
                    };
                    this.forceService.add(f);
                });
            }
        );
    }

    getRouterLink(force: Force) {
        return [force.id];
    }

    deleteForce(force: Force) {
        this.forceService.delete(force.id);
    }
}

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { IonicModule, Platform } from '@ionic/angular';
import { PurchaseRoutingModule } from './purchase.routing';
import { PurchaseListComponent } from './purchase-list/component';
import { PurchasingService } from './service/purchasing.service';
import { purchaseReducer } from './service/state';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageModule } from '../language';

@NgModule({
    declarations: [PurchaseListComponent],
    imports: [
        CommonModule,
        IonicModule,
        PurchaseRoutingModule,
        StoreModule.forFeature('purchase', purchaseReducer),
        TranslateModule,
        LanguageModule
    ],
    providers: [InAppPurchase2],
    schemas: [NO_ERRORS_SCHEMA]
})
export class PurchaseModule {
    constructor(private svc: PurchasingService, private platform: Platform) {
        // this.platform.ready().then(() => {
        //     setTimeout(() => {
        //         this.svc.restorePurchases();
        //     }, 1000);
        // });
    }

    static forRoot(): ModuleWithProviders<PurchaseModule> {
        return {
            ngModule: PurchaseModule,
            providers: [PurchasingService]
        };
    }
}

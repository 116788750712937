import { Component } from '@angular/core';
import { Product } from '../model';
import { PurchasingService } from '../service/purchasing.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'abs-purchase-list',
    template: `
        <abs-page-layout [title]="'GLOBAL.MENU_ITEMS.PURCHASES.TITLE' | translate">
            <div class="buttons">
                <ion-button (click)="restorePurchases()">{{ 'GLOBAL.PURCHASES.RESTORE' | translate }}</ion-button>
            </div>
            <ul *ngIf="debug || (products$ | async).length === 0">
                <li *ngFor="let statusLine of status$ | async">{{ statusLine }}</li>
            </ul>
            <ion-list class="transparent products" *ngIf="(products$ | async).length > 0">
                <ion-item *ngFor="let product of products$ | async; let i = index" lines="full" [class.alt]="i % 2 === 0">
                    <div class="product">
                        <h2 class="itemName">
                            {{ product.name | language | async }}
                        </h2>
                        <p class="itemDescription">
                            {{ product.description | language | async }}
                        </p>
                        <div class="buttons">
                            <ion-button (click)="purchase(product.key)" *ngIf="(purchases$ | async).indexOf(product.key) === -1">
                                {{ 'GLOBAL.PURCHASES.BUY' | translate }} - {{ product.price }}
                            </ion-button>
                            <ion-button [disabled]="true" *ngIf="(purchases$ | async).indexOf(product.key) > -1">{{
                                'GLOBAL.PURCHASES.OWNED' | translate
                            }}</ion-button>
                        </div>
                    </div>
                </ion-item>
            </ion-list>
        </abs-page-layout>
    `,
    styles: [
        `
            .products .product {
                width: 100%;
            }

            .products .buttons {
                padding: 0 0 20px;
            }

            .products .buttons ion-button {
                margin: 0;
            }

            ion-item {
                white-space: normal;
            }
        `
    ]
})
export class PurchaseListComponent {
    public purchases$ = this.purchasingSvc.purchases$;
    public products$ = this.purchasingSvc.products$;
    public status$ = this.purchasingSvc.status$;

    public debug = false;

    constructor(public purchasingSvc: PurchasingService) {}

    purchase(productId: string) {
        // this.purchasingSvc.purchase(productId);
    }

    restorePurchases() {
        this.purchasingSvc.restorePurchases();
    }
}

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'wl-game-list',
    template: `<abs-page-layout [title]="'GLOBAL.MENU_ITEMS.GAME_LIST' | translate">
        <div class="banners">
            <div class="banner" *ngFor="let game of games">
                <a [routerLink]="['/' + game]">
                    <img src="/assets/images/banners/{{ game }}.png" alt="{{ getTranslationKey(game) }} | translate" />
                </a>
            </div>
        </div>
    </abs-page-layout>`,
    styles: [
        `
            @media (min-width: 600px) {
                .banners {
                    /* grid-template-columns: auto auto auto; */
                }
            }
            .banners {
                display: grid;
            }
            .banner {
                position: relative;
            }

            img {
                display: block;
            }

            h2 {
                background: rgba(0, 0, 0, 0.7);
                padding: 10px 20px;
                color: white;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0;
            }
        `
    ]
})
export class GameListPage {
    games = ['bolt-action', 'black-seas', 'blood-red-skies'];
    constructor() {}

    getTranslationKey(gameId: string) {
        return 'GLOBAL.MENU_ITEMS.' + gameId.replace(/\-/g, '_').toUpperCase();
    }
}
